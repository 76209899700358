import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BatchTransactionsService } from 'src/app/blockModules/services/batch-transactions.service';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import { PageChanged, SortChanged } from 'src/app/sharedModules/models/pagination.model';
@Component({
  selector: 'app-view-batch-transactions',
  templateUrl: './view-batch-transactions.component.html',
  styleUrls: ['./view-batch-transactions.component.scss'],
})
export class ViewBatchTransactionsComponent implements OnInit {
  isLoading: boolean = false;
  loadMessage: string = 'Please wait ....';
  groupName: string = '';
  transactionType: string = '';
  customerGrpscolumn: TableColumn[] = [
    { displayName: 'CUSTOMER', name: 'name', width: '15%' },
    { displayName: 'UNIQUE ID', name: 'uniqueId', width: '10%' },
    { displayName: 'ACCT #', name: 'accountNumber', width: '8%' },
    { displayName: 'TYPE', name: 'achTransactionType', width: '8%' },
    { displayName: 'AMOUNT', name: 'defaultAmount', width: '10%' },
    { displayName: 'STATUS', name: 'batchTransactionStatus', width: '10%' },
    { displayName: 'NOTES', name: 'notes', width: '10%' },
    { displayName: 'INCLUDE', name: 'isInclude', width: '10%' },
  ];
  data: any;
  batchBasicDetails: any = {};
  @Input()
  noRecordMessage: string;
  allColumns: TableColumn[];
  userRole: any;
  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };
  datatablemargin: string = '0px';
  batchId: string;
  selectedCustomerList: any[] = [];
  companyName: string = '';
  companyId: string = '';
  batchName: string = '';
  note: string = '';
  initiatedBy: string = '';
  reviewedBy: string = '';
  memo: string = '';
  reviewedDate: any;
  effectiveDate: Date = new Date();
  nextCutOffTime: Date = new Date();
  cutOffTime: any;
  isBatchModify: boolean;
  isNoteSectionVisible: boolean;
  sortBy: SortChanged = {
    active: 'entryDate',
    direction: 'desc',
  };

  applyToAllForm: FormGroup;
  viewNoteForm: FormGroup;
  @ViewChild('applyToAllDialog') applyToAllDialog!: TemplateRef<any>;
  @ViewChild('viewNoteDialog') viewNoteDialog!: TemplateRef<any>;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private batchTransactionsService: BatchTransactionsService,
    private _toastrService: ToastrService,
    private dialog: MatDialog
  ) {
    this.allColumns = this.customerGrpscolumn;

    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];
    this.companyId = localStorage.getItem('CompanyId') || "";
    this.companyName = localStorage.getItem('CompanyName') || "";
  }

  transactionTypes = [
    { value: 'CREDIT', displayName: 'Credit Only' },
    { value: 'DEBIT', displayName: 'Debit Only' },
    { value: 'CREDIT & DEBIT', displayName: 'Credit & Debit' },
  ];

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.batchId = params.get('batchId');
    });
    this.loadData();
    this.viewNoteForm = this.fb.group({
      description: ['']
    });

  }

  navigateToBatchTransactions() {
    this.router.navigate(['batchTransactions']);
  }

  loadData() {
    if (this.batchId) {
      this.getAllCustomerByBatchId(this.batchId);
    }
  }

  getTransactionType(type: string) {
    let result = this.transactionTypes.find((obj) => obj.value === type);
    return result?.displayName || '';
  }

  getAllCustomerByBatchId(batchId: any) {
    this.isLoading = true;
    this.batchTransactionsService
      .getAllCustomerByBatchId(batchId)
      .subscribe((response) => {
        this.isLoading = false;
        if (response.data != null) {
          this.groupName = response.data.customerGroupName;
          this.transactionType = this.getTransactionType(response.data.groupTransactionType.toUpperCase());
          this.batchName = response.data.batchName;
          this.initiatedBy = response.data.initiatedBy;
          this.reviewedBy = response.data.reviewedBy;
          this.reviewedDate = this.getDateTimeInLocalZoneFormat(response.data.reviewedDate);
          this.memo = response.data.memo;
          this.effectiveDate = response.data.effectiveDate;
          this.nextCutOffTime = response.data.nextCutOffTime;
          this.isBatchModify = response.data.isActive
            && ((response.data.batchStatus.toLowerCase() == 'new' && this.userRole != 1)
              || (response.data.batchStatus.toLowerCase() == 'review' && (this.userRole == 0 || this.userRole == 3)));
          this.isNoteSectionVisible = response.data.batchStatus.toLowerCase() == 'declined';
          this.data = response.data.batchTransactionDetails;
          this.selectedCustomerList = this.data;
          this.page.length = this.data.length;
          this.onPageChanged(this.page);
          this.batchBasicDetails.effectiveDate = response.data.effectiveDate;
          this.batchBasicDetails.batchStatus = response.data.batchStatus;
          this.batchBasicDetails.fileSentDate = response.data.fileSentDate;
          this.getCutOffTimeWithLocalZoneFormat(this.batchBasicDetails);
        } else {
          this.data = [];
          this._toastrService.error(response.errorMessage);
        }
      }),
      (error) => {
        this.data = [];
        this.isLoading = false;
        this._toastrService.error(error.errorMessage);
      };
  }

  batchEdit() {
    this.router.navigate(['add-batch-transactions', this.batchId]);
  }

  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize ||
      this.groupName != ''
    ) {
      this.page = event;
      const startIndex = this.page.pageIndex * this.page.pageSize;
      const endIndex = startIndex + this.page.pageSize;
      this.data = this.selectedCustomerList.slice(startIndex, endIndex);
    }
  }

  openApplyToAllDialog() {
    const dialogRef = this.dialog.open(this.applyToAllDialog, {
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog result:', result);
    });
  }

  onCancel() {
    this.dialog.closeAll();
  }

  get getTotalTransactions(): number {
    return this.selectedCustomerList?.filter((record) => record.isInclude).length || 0;
  }

  get getTotalDebits(): number {
    return this.selectedCustomerList?.filter(record => record.isInclude && record.achTransactionType.toLowerCase() === 'debit')
      .reduce((sum, record) => sum + (Number(record.defaultAmount) || 0), 0) || 0;
  }

  get getTotalCredits(): number {
    return this.selectedCustomerList?.filter(record => record.isInclude && record.achTransactionType.toLowerCase() === 'credit')
      .reduce((sum, record) => sum + (Number(record.defaultAmount) || 0), 0) || 0;
  }

  onSortChanged(event: SortChanged) {
    if (this.sortBy.active !== event.active || this.sortBy.direction !== event.direction) {
      this.sortBy = event;

      if (this.data) {
        const { active, direction } = this.sortBy;

        const sortedList = [...this.data].sort((a, b) => {
          let valueA = a[active];
          let valueB = b[active];

          if (active === 'name') {
            valueA = `${a.firstName || ''} ${a.lastName || ''}`.trim();
            valueB = `${b.firstName || ''} ${b.lastName || ''}`.trim();
          }

          if (valueA === undefined || valueA === null || valueA === '') return direction === 'asc' ? -1 : 1;
          if (valueB === undefined || valueB === null || valueB === '') return direction === 'asc' ? 1 : -1;

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return direction === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
          } else if (typeof valueA === 'number' && typeof valueB === 'number') {
            return direction === 'asc' ? valueA - valueB : valueB - valueA;
          } else {
            const strA = String(valueA);
            const strB = String(valueB);
            return direction === 'asc' ? strA.localeCompare(strB) : strB.localeCompare(strA);
          }
        });

        this.data = sortedList;
      }
    }
  }

  openViewNoteDialog(data: any) {
    let customerRecord = this.data?.find(record => record.customerId == data);
    if (customerRecord) {
      this.note = customerRecord.notes;

      this.dialog.open(this.viewNoteDialog, {
        height: '280px',
        width: '350px',
        disableClose: true
      });
    }
  }

  getCutOffTimeWithLocalZoneFormat(batchDetails: any) {
    if (batchDetails == undefined && batchDetails?.batchStatus == undefined) {
      this.cutOffTime = { date: '', time: '' };
      return false;
    }
    const cutOffTime = (batchDetails.batchStatus.toUpperCase() != "NEW" || batchDetails.batchStatus.toUpperCase() != "QUEUED") && batchDetails.fileSentDate != undefined ? batchDetails.fileSentDate : batchDetails.effectiveDate;
    const momentTimezone = moment.tz.guess(); // Detect user's timezone
    const momentDate = moment
      .utc(cutOffTime instanceof Date ? cutOffTime : new Date(cutOffTime)) // Ensure the input is a Date
      .tz(momentTimezone); // Convert to the user's timezone

    // Format the date and time separately
    const formattedDate = momentDate.format('MM/DD/YYYY');
    const formattedTime = momentDate.format('hh:mm A');
    const todayDate = new Date();
    const currentMonth = String(todayDate.getMonth() + 1).padStart(2, '0');
    const currentDay = String(todayDate.getDate()).padStart(2, '0');
    const currentYear = todayDate.getFullYear();
    const formattedTodaysDate = `${currentMonth}/${currentDay}/${currentYear}`;
    // If the time is in the future, add 30 minutes
    if (formattedDate == formattedTodaysDate && (batchDetails.batchStatus.toUpperCase() == "NEW" || batchDetails.batchStatus.toUpperCase() == "QUEUED")) {
      // Get current UTC time
      const currentTimeUTC = moment.utc(todayDate);
      // Calculate the next rounded 30-minute mark
      const minutes = currentTimeUTC.minutes();
      const extraMinutes = (5 - (minutes % 5)) % 5; // Adds 5 minutes to the current time if needed
      const roundedTime = currentTimeUTC.add(extraMinutes, 'minutes');
      this.cutOffTime = {
        date: roundedTime.tz(momentTimezone).format('MM/DD/YYYY'),
        time: roundedTime.tz(momentTimezone).format('hh:mm A') + ' ' + moment.tz(momentTimezone).zoneAbbr(),
      };
      return false;
    }
    if (new Date(cutOffTime) > new Date()) {
      this.cutOffTime = {
        date: formattedDate,
        time: /*formattedTime + ' '*/ '12:00 AM ' + moment.tz(momentTimezone).zoneAbbr(),
      };
      return false;
    }
    this.cutOffTime = {
      date: formattedDate,
      time: formattedTime + ' ' + moment.tz(momentTimezone).zoneAbbr(),
    };
  }

  getDateTimeInLocalZoneFormat(inputDate: any) {
    if (inputDate) {
      const formattedDate = inputDate instanceof Date ? inputDate : new Date(inputDate);
      if (!isNaN(formattedDate.getTime())) {
        return formattedDate.toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true
        });
      }
      else {
        return '';
      }
    } else {
      return '';
    }
  }

  getNameInitials(fullName: string): string {
    if (fullName && fullName.trim()) {
      const nameParts = fullName.trim().split(' ');
      if (nameParts.length === 2) {
        return nameParts[0].charAt(0).toUpperCase() + nameParts[1].charAt(0).toUpperCase();
      }
      else {
        return nameParts[0].charAt(0).toUpperCase();
      }
    }
    return null;
  }
}
