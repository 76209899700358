<button *ngIf="isHandset$ | async" type="button" aria-label="Toggle side-nav" mat-icon-button (click)="drawer.toggle()">
  <mat-icon aria-label="SideNav toggle icon">menu</mat-icon>
</button>

<mat-sidenav-container class="side-nav-container" [style.--active-color-scoll]="themenaccolor">
  <mat-sidenav #drawer class="side-nav" [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false"
    [style.--active-color-bgColor]="themeColor?? '#1e4ed8'"
    >
    <div class="header-icon" (click)="navigateToDashboard()">
      <img src="{{ getCompanyLogoSrc() }}" alt="logo" class="logo" />
    </div>

    <mat-divider style="color: #1f2a3e"></mat-divider>

    <mat-nav-list>
      <section>
        <mat-list-item class="sectionName">GENERAL</mat-list-item>
        <mat-list-item (click)="navigateToPage(dashboard_const)" [ngClass]="{'hovers bagColor': clickedItem === dashboard_const}"
        [style.--active-color-bgColor]="themenaccolor?? '#1e4ed8'"
        >
          <div fxLayout="row" fxLayoutWrap fxLayoutGap="2px" fxLayoutAlign="start stretch">
            <div fxLayout="column" fxLayoutGap="2px">
              <img class="sectionSubImg" src="{{ getIconSrc(dashboard_const) }}" alt="" />
            </div>
            &nbsp; &nbsp;
            <div fxLayout="column" fxLayoutGap="2px">
              <mat-label class="sectionSubLabel" [style.--active-color-textColor]="themeColor?? '#1e4ed8'">Dashboard</mat-label>
            </div>
          </div>
        </mat-list-item>
        <mat-list-item (click)="navigateToPage(customers_const)"  
        [ngClass]="{ 'hovers bagColor': clickedItem === customers_const}" 
        [style.--active-color-bgColor]="themenaccolor?? '#1e4ed8'"
        >
          <div fxLayout="row" fxLayoutWrap fxLayoutGap="2px" fxLayoutAlign="start stretch">
            <div fxLayout="column" fxLayoutGap="2px">
              <img class="sectionSubImg" src="{{ getIconSrc(customers_const) }}" alt="" />
            </div>
            &nbsp; &nbsp;
            <div fxLayout="column" fxLayoutGap="2px">
              <mat-label class="sectionSubLabel" [style.--active-color-textColor]="themeColor?? '#1e4ed8'"> Customers</mat-label>
              
            </div>
          </div>
        </mat-list-item>
        <mat-list-item (click)="navigateToPage(reports_const)" [ngClass]="{'hovers bagColor': clickedItem === reports_const}"
        [style.--active-color-bgColor]="themenaccolor?? '#1e4ed8'"
        >
          <div fxLayout="row" fxLayoutWrap fxLayoutGap="2px" fxLayoutAlign="start stretch">
            <div fxLayout="column" fxLayoutGap="2px">
              <img class="sectionSubImg" src="{{ getIconSrc(reports_const) }}" alt="" />
            </div>
            &nbsp; &nbsp;
            <div fxLayout="column" fxLayoutGap="2px">
              <mat-label class="sectionSubLabel" [style.--active-color-textColor]="themeColor?? '#1e4ed8'" >Reports</mat-label>
            </div>
          </div>
        </mat-list-item>
        <mat-list-item (click)="navigateToPage(invoices_const)" [ngClass]="{'hovers bagColor': clickedItem === invoices_const}"
        [style.--active-color-bgColor]="themenaccolor?? '#1e4ed8'"
        >
          <div fxLayout="row" fxLayoutWrap fxLayoutGap="2px" fxLayoutAlign="start stretch">
            <div fxLayout="column" fxLayoutGap="2px">
              <img class="sectionSubImg" src="{{ getIconSrc(invoices_const) }}" alt="" />
            </div>
            &nbsp; &nbsp;
            <div fxLayout="column" fxLayoutGap="2px">
              <mat-label class="sectionSubLabel" [style.--active-color-textColor]="themeColor?? '#1e4ed8'" >Invoices</mat-label>
            </div>
          </div>
        </mat-list-item>
      </section>
      <section>
        <mat-list-item class="sectionName">BANKING</mat-list-item>
        <mat-list-item (click)="navigateToPage(instantBankAnalysis_const)" [ngClass]="{'hovers bagColor': clickedItem === instantBankAnalysis_const}"
        [style.--active-color-bgColor]="themenaccolor?? '#1e4ed8'"
        >
          <div fxLayout="row" fxLayoutWrap fxLayoutGap="2px" fxLayoutAlign="start stretch">
            <div fxLayout="column">
              <img class="sectionSubImg" src="{{ getIconSrc(instantBankAnalysis_const) }}" alt="" />
            </div>
            &nbsp; &nbsp;
            <div fxLayout="column" fxLayoutGap="2px">
              <mat-label class="sectionSubLabel" [style.--active-color-textColor]="themeColor?? '#1e4ed8'" >Instant Bank Analysis</mat-label>
            </div>
          </div>
        </mat-list-item>
        <!-- <mat-list-item (click)="navigateToPage(customer_score_stats_const)" [ngClass]="{'hovers bagColor': clickedItem === customer_score_stats_const}">
          <div fxLayout="row" fxLayoutWrap fxLayoutGap="2px" fxLayoutAlign="start stretch">
            <div fxLayout="column" fxLayoutGap="2px">
              <img class="sectionSubImg" src="{{ getIconSrc(reports_const) }}" alt="" />
            </div>
            &nbsp; &nbsp;
            <div fxLayout="column" fxLayoutGap="2px">
              <mat-label class="sectionSubLabel">Score Stats Report</mat-label>
            </div>
          </div>
        </mat-list-item>  -->
      </section>
      <section>
        <mat-list-item class="sectionName">PAYMENTS</mat-list-item>
        <mat-list-item (click)="navigateToPage(transactions_const)" [ngClass]="{'hovers bagColor': clickedItem === transactions_const}"
        [style.--active-color-bgColor]="themenaccolor?? '#1e4ed8'"
        >
          <div fxLayout="row" fxLayoutWrap fxLayoutGap="2px" fxLayoutAlign="start stretch">
            <div fxLayout="column" fxLayoutGap="2px">
              <img class="sectionSubImg" src="{{ getIconSrc(transactions_const) }}" alt="" />
            </div>
            &nbsp; &nbsp;
            <div fxLayout="column" fxLayoutGap="2px">
              <mat-label class="sectionSubLabel" [style.--active-color-textColor]="themeColor?? '#1e4ed8'" >Transactions</mat-label>
            </div>
          </div>
        </mat-list-item>
        <mat-list-item (click)="navigateToPage(batch_transactions_const)" [ngClass]="{'hovers bagColor': clickedItem === batch_transactions_const}"
        [style.--active-color-bgColor]="themenaccolor?? '#1e4ed8'"
        >
          <div fxLayout="row" fxLayoutWrap fxLayoutGap="2px" fxLayoutAlign="start stretch">
            <div fxLayout="column" fxLayoutGap="2px">
              <img class="sectionSubImg" src="{{ getIconSrc(batch_transactions_const) }}" alt="" />
            </div>
            &nbsp; &nbsp;
            <div fxLayout="column" fxLayoutGap="2px">
              <mat-label class="sectionSubLabel" [style.--active-color-textColor]="themeColor?? '#1e4ed8'" >Batch Transactions</mat-label>
            </div>
          </div>
        </mat-list-item> 
        <mat-list-item *ngIf = "checkCompanyPermission()" (click)="navigateToPage(statements_const)" [ngClass]="{'hovers bagColor': clickedItem === statements_const}"
        [style.--active-color-bgColor]="themenaccolor?? '#1e4ed8'"
        >
          <div fxLayout="row" fxLayoutWrap fxLayoutGap="2px" fxLayoutAlign="start stretch">
            <div fxLayout="column" fxLayoutGap="2px">
              <img class="sectionSubImg" src="{{ getIconSrc(statements_const) }}" alt="" />
            </div>
            &nbsp; &nbsp;
            <div fxLayout="column" fxLayoutGap="2px">
              <mat-label class="sectionSubLabel" [style.--active-color-textColor]="themeColor?? '#1e4ed8'" >Statements</mat-label>
            </div>
          </div>
        </mat-list-item>        
        <mat-list-item (click)="navigateToPage(billing_const)" [ngClass]="{'hovers bagColor': clickedItem === billing_const}">
          <div fxLayout="row" fxLayoutWrap fxLayoutGap="2px" fxLayoutAlign="start stretch">
            <div fxLayout="column" fxLayoutGap="2px">
              <img class="sectionSubImg" src="{{ getIconSrc(billing_const) }}" alt="" />
            </div>
            &nbsp; &nbsp;
            <div fxLayout="column" fxLayoutGap="2px">
              <mat-label class="sectionSubLabel">Billing</mat-label>
            </div>
          </div>
        </mat-list-item>
      </section>
      <section>
        <mat-list-item class="sectionName">MANAGEMENT</mat-list-item>        
        <mat-list-item (click)="navigateToPage(finzeoUsers_const)" [ngClass]="{'hovers bagColor': clickedItem === finzeoUsers_const}"
        [style.--active-color-bgColor]="themenaccolor?? '#1e4ed8'"
        >
          <div fxLayout="row" fxLayoutWrap fxLayoutGap="2px" fxLayoutAlign="start stretch">
            <div fxLayout="column" fxLayoutGap="2px">
              <img class="sectionSubImg" src="{{ getIconSrc(finzeoUsers_const) }}" alt="" />
            </div>
            &nbsp; &nbsp;
            <div fxLayout="column" fxLayoutGap="2px">
              <mat-label class="sectionSubLabel" [style.--active-color-textColor]="themeColor?? '#1e4ed8'">Users</mat-label>
            </div>
          </div>
        </mat-list-item>       
      </section>

      <section>
        <mat-list-item class="sectionName">Help</mat-list-item>        
        <mat-list-item (click)="navigateToPage(ticket_const)" [ngClass]="{'hovers bagColor': clickedItem === ticket_const}"
        [style.--active-color-bgColor]="themenaccolor?? '#1e4ed8'"
        >
          <div fxLayout="row" fxLayoutWrap fxLayoutGap="2px" fxLayoutAlign="start stretch">
            <div fxLayout="column" fxLayoutGap="2px">
              <img class="sectionSubImg" src="{{ getIconSrc(ticket_const) }}" />
            </div>
            &nbsp; &nbsp;
            <div fxLayout="column" fxLayoutGap="2px">
              <mat-label class="sectionSubLabel" [style.--active-color-textColor]="themeColor?? '#1e4ed8'">Create A Ticket</mat-label>
            </div>
          </div>
        </mat-list-item>       
      </section>
      <section>
        <mat-list-item class="sectionName">OTHERS</mat-list-item>                
        
        <mat-list-item *ngIf="userRole != 'Customer Support Representative' && userRole != 'Viewer'" (click)="navigateToPage(Setting_const)" 
        [ngClass]="{'hovers bagColor': clickedItem === Setting_const}"
        [style.--active-color-bgColor]="themenaccolor?? '#1e4ed8'"
        >
          <div fxLayout="row" fxLayoutWrap fxLayoutGap="2px" fxLayoutAlign="start stretch">
            <div fxLayout="column" fxLayoutGap="2px">
              <img class="sectionSubImg" src="{{ getIconSrc(settings_const) }}" alt="" />
            </div>
            &nbsp; &nbsp;
            <div fxLayout="column" fxLayoutGap="2px">
              <mat-label class="sectionSubLabel" [style.--active-color-textColor]="themeColor?? '#1e4ed8'">Settings</mat-label>
            </div>
          </div>
        </mat-list-item> 
        
        <mat-list-item (click)="navigateToPage(inventory_const1)" [ngClass]="{'hovers bagColor': clickedItem === inventory_const1}"
        [style.--active-color-bgColor]="themenaccolor?? '#1e4ed8'"
        >
          <div fxLayout="row" fxLayoutWrap fxLayoutGap="2px" fxLayoutAlign="start stretch">
            <div fxLayout="column" fxLayoutGap="2px">
              <img class="sectionSubImg" src="{{ getIconSrc(inventory_const) }}" />
            </div>
            &nbsp; &nbsp;
            <div fxLayout="column" fxLayoutGap="2px">
              <mat-label class="sectionSubLabel" [style.--active-color-textColor]="themeColor?? '#1e4ed8'">Inventory</mat-label>
            </div>
          </div>
        </mat-list-item>  
      </section>

    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <!-- At this stage, unable to call any other angular Component inside another.
      So added raw part of header component
      -->
    <mat-toolbar color="primary" class="mat-primary">
      <mat-toolbar-row>
        <span class="spacer"></span>

        <!-- <button mat-icon-button> commented as per discussion 20-10-2022
          <mat-icon aria-hidden="false" class="notificationIcon" [matBadgeHidden]="notificationsCount == 0"
            matBadge="{{ notificationsCount }}" matBadgeColor="warn">notifications_none</mat-icon>
        </button>

        <mat-divider vertical class="header-divider"></mat-divider> -->

        <div>
          <mat-label class="userNameCircle" [matMenuTriggerFor]="menu">{{
            loggedInUserInitial
            }}</mat-label>
        </div>

        <div fxLayout="row" style="line-height: 100%" [matMenuTriggerFor]="menu" >
          <div fxLayout="column" fxLayoutGap="2px" >
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
              <mat-label class="loggedInUserName">{{
                loggedInUserName
                }}</mat-label>
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch" style="cursor: pointer;">
              <mat-label style="font-size: 12px; color: #a1a1a9">{{
                userRole
                }}</mat-label>
            </div>
          </div>
        </div>
        <div style="margin-top: 2px;color:gray;cursor: pointer;" [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="navigateToProfile()"><mat-icon>account_circle</mat-icon>Profile</button>
      <button mat-menu-item (click)="reviewEula()"><mat-icon>assignment</mat-icon>Review Eula</button>
      <button mat-menu-item (click)="logout()"><mat-icon>logout</mat-icon>Logout</button>
    </mat-menu>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>