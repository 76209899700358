import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { AchTransactionsService } from 'src/app/featureModules/services/ach-transactions.service';
import { ExportDialogComponent } from 'src/app/sharedModules/components/dialogs/export-dialog/export-dialog.component';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  DBFilterOptions,
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';

const REFRESH_PAGING_TIMEOUT_MS = 350;
export type ListFilterObj = { [key: string]: string | number | boolean };
export type ListFilter = string | ListFilterObj;

@Component({
  selector: 'app-ach-transactions-summary',
  templateUrl: './ach-transactions-summary.component.html',
  styleUrls: ['./ach-transactions-summary.component.scss'],
})
export class AchTransactionsSummaryComponent implements OnInit,OnDestroy {
  @Input()
  listRefreshEvent = new EventEmitter();

  selection = new SelectionModel<TableColumn>(true, []);

  @Input()
  filterEvent = new EventEmitter();

  @Input()
  noRecordMessage: string;

  data: any;
  isLoading = false;
  loadMessage: string = '';
  isLoadingPaging = false;
  filter: ListFilter = '';
  countFilter: ListFilter;
  gotDataResponse = false;
  actionRefresh = false;
  maxLength: string;
  gridData;
  isAccess = false;
  userRole :any;

  private timeout: number;

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: 'TransactionCount',
    direction: 'asc',
  };

  columns: TableColumn[] = [
    { displayName: '', name: 'icon', width: '5%' },
    { displayName: 'Name', name: 'CustomerName', width: '10%' },
    {
      displayName: 'Total Transactions',
      name: 'TransactionCount',
      width: '15%',
    },
    { displayName: 'Total Amount', name: 'TotalAmount', width: '10%' },
    { displayName: 'Pending', name: 'PendingCount', width: '10%' },
    { displayName: 'Failed', name: 'FailedCount', width: '10%' },
    { displayName: 'Returned', name: 'ReturnCount', width: '10%' },
    { displayName: 'Noc', name: 'NocCount', width: '10%' },
    { displayName: 'Success', name: 'SuccessCount', width: '10%' },
    { displayName: 'Recurring', name: 'RecurringCount', width: '10%' }   
  ];

  allColumns: TableColumn[];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private _achTransactionService: AchTransactionsService,
    private _commonService: CommonService
  ) {
    this.allColumns = this.columns;

    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];
    
    this.filter = this._commonService.getListDataFromLocalStorage(
      'achSummaryGridFilter',
      'ach',
      ''
    );

    this.page = {
      pageIndex: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'achSummaryGridPageIndex',
          'ach',
          0
        )
      ),
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'achSummaryGridPageSize',
          'ach',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'achSummaryGridSortActive',
        'ach',
        'TransactionCount'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'achSummaryGridSortDirection',
        'ach',
        'asc'
      ),
    };
  }

  ngOnInit(): void {
    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize
    );
    this.listRefreshEvent.subscribe(() => {
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize
      );
    });
    this.filterEvent.subscribe(this.onFilter.bind(this));
  }

  onRefresh(id: string) {}

  loadData(
    filter: ListFilter,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    customerType?: boolean,
    customerId?: string
  ) {
    this.isLoading = true;
    this._achTransactionService
      .getCustomerAchSummaryTransactions(
        customerId,
        filter?.toString(),
        customerType,
        sortBy,
        sortDirection,
        page,
        pageSize
      )
      .subscribe({
        next: (res) => {  
          this.isLoading = false;        
          this.isAccess = true;
          if (res.data != null) {
           var responseData = res.data;
            this.page.length = res.data.totalItems;
            var list = [];
            res.data?.items.map((item) => {
              let record = {
                CustomerId: item.customerId,
                CustomerName: item.customerName,
                firstName: item.customerName?.trim().split(' ')?.[0],
                lastName: item.customerName?.trim().split(' ')?.[1],
                email: item.email,
                RecurringCount: item.recurringCount,
                SuccessCount: item.successCount,
                ReturnCount: item.returnCount,
                FailedCount: item.failedCount,
                SentCount: item.sentCount,
                PendingCount: item.pendingCount + item.sentCount,
                CapturedCount: item.capturedCount,
                TotalAmount: item.totalAmount,
                TransactionCount: item.transactionCount,
                NocCount: item.nocCount,
              };
              list.push(record);
            });
            this.gridData = list;
          } else {
            this.gridData = [];
          }
        },
        error: (error) => {
          this.isAccess = false;
          this.isLoading = false;
          //this._toastrService.error('Something went wrong, Please contact administrator!');
        },
      });
  }

  public onFilter(event: ListFilter = '') {
    if (this.filter !== event) {
      this.page.pageIndex = 0;

      this.filter = event;
      if (typeof this.filter === 'string' && this.filter) {
        this.filter = this.filter.replace("'", "''");
      }
      this.loadData(
        this.filter?.toString(),
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize
      );

      this._commonService.setListDataToLocalStorage(
        this.filter.toString(),
        'achSummaryGridFilter',
        'ach'
      );
    }
  }
  onExport(event: any) {
    const dialogRef = this.dialog.open(ExportDialogComponent, {
      height: '65%',
      width: '50%',
    });
  }

  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;
      this.loadData(
        this.filter?.toString(),
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize
      );

      this._commonService.setListDataToLocalStorage(
        event.pageSize.toString(),
        'achSummaryGridPageSize',
        'ach'
      );
      this._commonService.setListDataToLocalStorage(
        event.pageIndex.toString(),
        'achSummaryGridPageIndex',
        'ach'
      );
    }
  }

  onSortChanged(event: SortChanged) {
    const column1: TableColumn = this.columns[event.active];
    var column = this.columns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
      this.sortByMapping();
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize
      );

      this._commonService.setListDataToLocalStorage(
        event.active,
        'achSummaryGridSortActive',
        'ach'
      );
      this._commonService.setListDataToLocalStorage(
        event.direction,
        'achSummaryGridSortDirection',
        'ach'
      );
    }
  }
  sortByMapping() {
    switch (this.sortBy.active) {
      case 'name':
        this.sortBy.active = 'firstName';
        break;
      case 'type':
        this.sortBy.active = 'customerType';
        break;
    }
  }

  protected refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.countFilter = filter;
    this.page.length = 0;
    this.timeout = window.setTimeout(() => {
      this._refreshPaging(filter, count, options);
    }, REFRESH_PAGING_TIMEOUT_MS);
  }

  private _refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    this.isLoadingPaging = true;
    const countOptions = Object.assign({}, options);
    delete countOptions.$orderBy;
    delete countOptions.$skip;
    delete countOptions.$top;
    delete countOptions.$expand;

    countOptions.$count = true;
    if (this.maxLength) {
      countOptions.$top = Number(this.maxLength);
    }

    count(countOptions)
      .pipe(
        map((response: number) => {
          if (filter === this.countFilter) {
            this.page.length = response > 0 ? response : 0;
            this.isLoading = this.gotDataResponse == false ? true : false;
          }
        }),
        catchError((err) => {
          this.onError(err.message);
          this.isLoading = false;
          return of([]);
        })
      )
      .subscribe(() => {
        this.isLoadingPaging = false;
      });
  }
  onError(message: any) {
    throw new Error('Method not implemented.');
  }

  public onRowClick($event: any) {
    this.router.navigate(['customer-ach', $event?.CustomerId]);
  }

  navigationToPage() {
    this.router.navigate(['add-ach'], {
      state: {
        url: '/transactions',
        tabIndex: 0,
      },
    });
  }
  ngOnDestroy() {
    this.filterEvent.unsubscribe();
    this.listRefreshEvent.unsubscribe();
    clearTimeout(this.timeout);
    if(!(this.router.url.includes('transactions') || this.router.url.includes('customer-ach') || this.router.url.includes('add-ach'))){
      localStorage.removeItem('ach');
    }  
  }
}
