<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<div class="backHeader">
  <mat-label>
    <button mat-icon-button (click)="navigateToTransactionsPage()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <span *ngIf="!existingCustomer"> Credit/Debit Transactions Summary </span>
    <span *ngIf="existingCustomer"> Credit/Debit Transactions Details </span>
  </mat-label>
</div>
<br />

<div class="add-page-header-spacing page-header-text">
  Create Credit/Debit Transactions
</div>
<div class="add-page-header-spacing page-header-sub-text-1">
  All individual & company related transactions can be created from this page
</div>
<br />

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
  <div fxLayout="column" fxFlex="40%">
    <img src="assets/grid-icons/addTransaction.png" />
  </div>
  <div fxLayout="column" fxFlex="50%" style="background: #f5f5f5">
    <mat-label class="create-now">Create Now</mat-label>
    <mat-stepper
      orientation="vertical"
      [linear]="true"
      #stepper
      style="background: #f5f5f5"
    >
      <ng-template matStepperIcon="edit">
        <mat-icon>check</mat-icon>
      </ng-template>
      <mat-step [stepControl]="firstFormGroup" *ngIf="!existingCustomer">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel class="stepper-header-style">
            <span class="stepper-header-label">
              CUSTOMER
              <span *ngIf="userName != ''"> : {{ userName }} </span>
            </span>
          </ng-template>
          <br />

          <mat-label class="inner-stepper-text">Select Customer</mat-label>
          <app-search-customer
            style="margin-top: 2%"
            (onCustomerSelectionEvent)="onCustomerSelection($event)"
          >
          </app-search-customer>

          <div style="padding-top: 10px">
            <button
              mat-stroked-button
              matStepperNext
              class="page-header-button-style"
            >
              CONTINUE
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="firstFormGroup" *ngIf="existingCustomer">
        <form [formGroup]="firstFormGroup">
          <ng-template matStepLabel class="stepper-header-style">
            <span class="stepper-header-label">
              CUSTOMER
              <span *ngIf="userName != ''"> : {{ userName }} </span>
            </span>
          </ng-template>
        </form>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup">
        <form [formGroup]="secondFormGroup">
          <ng-template matStepLabel class="stepper-header-style">
            <span class="stepper-header-label">
              CARD NAME
              <!-- <span *ngIf="processorName"> : {{ processorName }} </span> -->
              <span *ngIf="selectedCardName"> - {{ selectedCardName }} </span>
            </span>
          </ng-template>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutGap="16px"
            fxLayoutAlign="start stretch"
          >
            <div fxLayout="column" fxFlex="100%">
              <div class="inner-stepper-text">Choose Card</div>
            </div>
          </div>
          <!-- <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutGap="16px"
            fxLayoutAlign="start stretch"
          >
            <div fxLayout="column" fxFlex="100%">
              <mat-form-field appearance="outline">
                <mat-label>Select Processor</mat-label>
                <mat-select name="processorCtrl" formControlName="processorCtrl"
                  (selectionChange)="processorSelection($event.value)">
                  <mat-option *ngFor="let processor of processors" [value]="processor.value">
                    {{ processor.viewValue }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div> -->
          <div *ngIf="cardList.length > 0">
            <div *ngFor="let card of cardList">
              <div class="bank-account">
                <div
                  fxLayout="row"
                  fxLayout.xs="column"
                  fxLayoutWrap
                  fxLayoutGap="16px"
                  fxLayoutAlign="start stretch"
                  style="padding: 20px"
                >
                  <div fxLayout="column" fxFlex="5%">
                    <div style="margin-top: 8px">
                      <mat-radio-group
                        (change)="onCardSelected($event, card)"
                        fxFlex="100%"
                        class="form-control"
                        name="bankSelection"
                        style="font-size: 12px"
                      >
                        <mat-radio-button
                          class="add-page-radio-button"
                          [value]="true"
                        >
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                  <div fxLayout="column" fxFlex="80%">
                    <div fxLayout="row">{{ getCardName(card.cardNumber) }}</div>
                    <div fxLayout="row">
                      {{ cardNumberEncryption(card.cardNumber) }}
                    </div>
                  </div>                  
                  <div fxLayout="column" fxFlex="15%">
                    <div style="margin-top: 8px">
                      <img
                        width="34"
                        height="24"
                        src="{{ getCardImage(card.cardNumber) }}"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center">
              <mat-label fxFlex="20%" class="required cvvLable">Enter CVV :</mat-label>
              <mat-form-field appearance="outline" fxFlex="15%">                
                <input
                  matInput
                  type="password"   
                  class ="password-input custom-password-input"                                       
                  name="cvv"
                  formControlName="cvv"
                  maxlength="4"
                  minlength="3"
                  placeholder="CVV"                        
                  autocomplete="cc-csc"                                               
                />                                                      
                <mat-error *ngIf="f['cvv'].errors?.['required']">CVV is Required</mat-error>
                <mat-error *ngIf="f['cvv'].errors?.['pattern']">Only integers allowed</mat-error>
                <mat-error *ngIf="f['cvv'].errors?.['minlength']">Min 3 digits required</mat-error>                                
              </mat-form-field>
            </div>
          </div>          
          <div *ngIf="cardList.length == 0">
            <div
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutWrap
              fxLayoutGap="16px"
              fxLayoutAlign="start stretch"
              style="padding: 20px"
            >
              <div fxLayout="column" fxFlex="100%">
                <span class="inner-stepper-text"
                  >No cards available. Please add new card for this customer
                  using 'Customers' page to create transaction</span
                >
              </div>
            </div>
            <br />
          </div>
          <div style="padding-top: 10px">
            <button
              mat-stroked-button
              matStepperNext
              class="page-header-button-style"
            >
              CONTINUE
            </button>
            &nbsp;&nbsp;
            <button mat-button matStepperPrevious class="white-button-style">
              BACK
            </button>
          </div>
        </form>
      </mat-step>
      <mat-step [stepControl]="thirdFormGroup">
        <form [formGroup]="thirdFormGroup">
          <ng-template matStepLabel class="stepper-header-style">
            <mat-label class="stepper-header-label">AMOUNT</mat-label>
          </ng-template>
          <br />
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <div fxFlex="100%" style="margin-bottom: 5px">
              <span class="inner-stepper-text"
                >How much amount you want to transfer?</span
              >
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <div fxFlex="100%" style="margin-bottom: 5px">
              <mat-form-field appearance="outline">
                <mat-label>Amount</mat-label>
                <input
                  type="text"
                  (focusout)="calculateTotalAmount()"
                  matInput
                  formControlName="amount"
                  placeholder="Amount"
                  appTwoDigitDecimalNumber
                  autocomplete="off"
                />
                <span matPrefix class="material-icons">attach_money</span>
                <mat-error>
                  {{ thirdFormGroup.controls["amount"]["_errorMessage"] }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <div fxFlex="80%" style="margin-bottom: 5px">
              <section class="example-section">
                <mat-checkbox
                  class="example-margin"
                  (change)="recurringChecked($event)"
                  formControlName="IsRecurring"
                >
                  Recurring Transaction
                </mat-checkbox>
              </section>
            </div>
          </div>
          <div
            *ngIf="isRecurring"
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <div fxFlex="45%">
              <mat-form-field appearance="outline">
                <mat-label>Number of Payments</mat-label>
                <input
                  type="number"
                  (focusout)="calculateTotalAmount()"
                  matInput
                  formControlName="installments"
                  placeholder="Number of Payments"
                />
                <mat-error>
                  {{ thirdFormGroup.controls["installments"]["_errorMessage"] }}
                </mat-error>
              </mat-form-field>
            </div>
            <div fxFlex="5%"></div>
            <div fxFlex="45%">
              <mat-form-field appearance="outline">
                <mat-label>Frequency</mat-label>
                <mat-select name="payFrequency" formControlName="payFrequency" (selectionChange)="onFrequencyChanged($event.value)">
                  <mat-option
                    *ngFor="let frequency of frequencies"
                    [value]="frequency.value"
                  >
                    {{ frequency.viewValue }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  {{ thirdFormGroup.controls["payFrequency"]["_errorMessage"] }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div
            fxLayout="row"
            *ngIf="isRecurring"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <div fxFlex="45%">
              <mat-form-field appearance="outline">
                <mat-label>Total Amount</mat-label>
                <input
                  type="text"
                  matInput
                  readonly
                  formControlName="totalAmount"
                  placeholder="Total Amount"
                />
              </mat-form-field>
            </div>
            <div fxFlex="5%"></div>
            <div fxFlex="45%">
              <mat-form-field appearance="outline">
                <mat-label>Transaction date</mat-label>
                <input
                  matInput
                  [matDatepicker]="datepicker"
                  formControlName="scheduledDate"
                  [min]="defaultScheduledDate"
                  [max]="maxDate"
                  autocomplete="scheduledDate" 
                  readonly                                                      
                  placholder="MM/DD/YYYY"
                />
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="datepicker"
                ></mat-datepicker-toggle>
                <mat-datepicker
                  #datepicker
                  [startAt]="defaultScheduledDate"
                >
                  <mat-datepicker-actions>
                    <button mat-button matDatepickerCancel>Cancel</button>
                    <button
                      mat-raised-button
                      color="primary"
                      matDatepickerApply
                    >
                      Apply
                    </button>
                  </mat-datepicker-actions>
                </mat-datepicker>
                <mat-error>
                  {{
                    thirdFormGroup.controls["scheduledDate"]["_errorMessage"]
                  }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>  
          <!-- <div fxLayout="row" *ngIf="isRecurring" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start stretch"
              class="form-group">
            <div fxFlex="30%">            
              <mat-checkbox  (change)="endOfMonthCheck($event)"
                formControlName="isEndOfMonth">
                At end of the month
              </mat-checkbox>
              <mat-error>
                {{
                  thirdFormGroup.controls["isEndOfMonth"]["_errorMessage"]
                }}
              </mat-error>            
            </div>
          </div>       -->
          <br /> <br />
          <div
            fxLayout="row"
            *ngIf="isRecurring && this.thirdFormGroup.controls['scheduledDate'].value"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutAlign="start stretch"
            class="form-group"
          >
            <div fxFlex="90%">
              <label class="notice-text">
                Notes: For recurring payments, the initial payment made today is not counted towards the total number of scheduled recurring payments that begin on {{thirdFormGroup.controls["scheduledDate"].value | date:'MM/dd/yyyy'}}.
              </label>              
            </div>
          </div>
          <br /><br />
        </form>
        <div style="padding-top: 10px">
          <button
            mat-stroked-button
            matStepperNext
            class="page-header-button-style"
            (click)="onSubmit()"
          >
            SUBMIT
          </button>
          &nbsp;&nbsp;
          <button mat-button matStepperPrevious class="white-button-style">
            BACK
          </button>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
  <div fxLayout="column" fxFlex="10%" style="background: #f5f5f5"></div>
</div>
