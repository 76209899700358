import {
  required,
  prop,
  maxLength,
  pattern,
  minLength,
} from '@rxweb/reactive-form-validators';

export class CreateBatchModel {
  @required()
  @maxLength({ value: 60 })
  @minLength({ value: 3 })
  @pattern({ expression: { Alphanumeric: /^[a-zA-Z0-9 ()]*$/ } })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  BatchName: string;

  @prop()
  @required()
  EffectiveDate: string;

  @prop()
  customerGroupId: string;

  @prop()
  customerGroupName: string;

  @prop()
  IsActive: boolean = true;

  @prop()
  BatchStatus: string = 'New';

  @prop()
  @required()
  TransactionType: string;
}
