import { prop, required } from '@rxweb/reactive-form-validators';

export class GenerateReportModel {
  @prop()
  customerId: any;

  @prop()
  customerName: string;

  @prop()
  typeOfService: string;

  // @prop()
  // processor: any;

  // @prop()
  // processorId: any;

  @prop()
  @required()
  startDate: any;

  @prop()
  @required()
  endDate: any;

  @prop()
  status: string;

  @prop()
  sortData: any;

  @prop()
  showDateType: string;

  @prop()
  fromAmount: number;

  @prop()
  toAmount: number;

  @prop()
  customerType: boolean;

  @prop()
  enteredBy: string;

  @prop()
  installments: number = 0;

  @prop()
  payFrequency: string;

  @prop()
  sortBy: string;

  @prop()
  sortDirection: string;

  @prop()
  page: number;

  @prop()
  pageSize: number;

  @prop()
  checkNumber: string;

  @prop()
  trackingId: string;

  @prop()
  merchantId: string;

  @prop()
  accountType: string;

  @prop()
  paymentType: string;
  
  @prop()
  entryCode: string;

  @prop()
  heading: string;

  @prop()
  description: string;

  @prop()
  isRecurring: boolean = false;

  @prop()
  returnStatusCode: string;

  @prop()
  nocStatusCode: string;

  @prop()
  withNoc: boolean=false;

}
