import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserManagementService } from 'src/app/featureModules/services/user-management.service';
import { LeftSideNavComponent } from 'src/app/sharedModules/components/left-side-nav/left-side-nav.component';
import { AddCustomerModel } from 'src/app/sharedModules/models/add-customer.model';
import { addUserModel } from 'src/app/sharedModules/models/add-user.model';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit {
  customerModel: AddCustomerModel;
  userModel: addUserModel;
  isLoading = false;
  userId: any;
  firstName: string;
  isAdminLogin: boolean;

  constructor(private router: Router, private leftNav: LeftSideNavComponent) {
    this.isAdminLogin =
      JSON.parse(localStorage.getItem('isAdminLogin')) ?? false;
  }

  ngOnInit(): void {
    this.userModel = new addUserModel();
    var userProfile = JSON.parse(localStorage.getItem('userProfile'));

    this.userModel.firstName = userProfile['firstName'];
    this.userModel.lastName = userProfile['lastName'];
    this.userModel.email = userProfile['email'];
    this.userModel.jobTitle = userProfile['jobTitle'];
    this.userModel.role = userProfile['role'];
    if (this.userModel.role == '0') {
      this.userModel.role = 'Admin';
    } else if (this.userModel.role == '1' && this.isAdminLogin) {
      this.userModel.role = 'Customer Support Representative';
    } else if (this.userModel.role == '1' && !this.isAdminLogin) {
      this.userModel.role = 'Viewer';
    } else if (this.userModel.role == '2') {
      this.userModel.role = 'Initiator';
    } else if (this.userModel.role == '3') {
      this.userModel.role = 'Approver';
    }

    this.userModel.phone = userProfile['phone'];
    this.userId = userProfile['userId'];
  }

  isEditEnabled() {
    if (this.isAdminLogin) return false;
    else if(this.userModel.role == 'Customer Support Representative' || this.userModel.role == 'Viewer') {
      return false
    }
    else return true;
  }

  getNameInitials(firstName: string, lastName: string) {
    if (firstName && lastName) {
      return (firstName.charAt(0) + lastName.charAt(0)).trim().toUpperCase();
    } else if (firstName) {
      return (firstName.charAt(0) + firstName.charAt(1)).trim().toUpperCase();
    }
    return null;
  }

  onEditClick() {
    this.leftNav.clickedItem = 'finzeoUsers';
    this.router.navigate(['/edit-user', this.userId]);
  }

  navigateToDashboard() {
    this.leftNav.clickedItem = 'dashboard';
    this.router.navigateByUrl('/dashboard');
  }
}
