<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<div style="background-color: #ebeef1" *ngFor="let bank of bankList">
  <mat-accordion class="accoridan-style">
    <mat-expansion-panel
      (opened)="bank.panelOpenState = true"
      (closed)="bank.panelOpenState = false"
      [expanded]="panelOpenState"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <mat-label class="bankHeading">{{ bank.bankName }}</mat-label>
          <!-- &nbsp;&nbsp;&nbsp; -->
          <!-- <mat-label *ngIf="bank.isVarified" class="bankVerified" style="color: #4CAF50;">Verified</mat-label>
          <mat-label *ngIf="!bank.isVarified" class="bankVerified" style="color: #fb8c00;">Not Verified</mat-label> -->
        </mat-panel-title>
        <mat-action-row style="padding-right: 20px">
          <img
            src="assets/grid-icons/dummy-bank.png"
            height="28px"
            width="28px"
          />
        </mat-action-row>
      </mat-expansion-panel-header>

      <mat-divider></mat-divider>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <div fxFlex="30%">
          <mat-label class="showBankHeading">Bank Account Number: </mat-label>
        </div>
        <div fxFlex="70%">

          <mat-label class="showBankValue set-monospace-font">
            {{ bank.bankAccountNumber | maskPipe : bank.bankAccountNumber.length==4?2:3 }}

          </mat-label>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <div fxFlex="30%">
          <mat-label class="showBankHeading">Routing Number: </mat-label>
        </div>
        <div fxFlex="70%">
          <mat-label class="showBankValue set-monospace-font">
            {{ bank.routingNumber | maskPipe : 3 }}
          </mat-label>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <div fxFlex="30%">
          <mat-label class="showBankHeading">Account Type: </mat-label>
        </div>
        <div fxFlex="70%">
          <mat-label class="showBankValue">
            {{ bank.accountType | titlecase }}
          </mat-label>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <div fxFlex="30%">
          <mat-label class="showBankHeading">Primary Account: </mat-label>
        </div>
        <div fxFlex="70%">
          <mat-label class="showBankValue">
            {{ bank.isPrimary == true ? "Yes" : "No" }}
          </mat-label>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
        <div fxFlex="30%">
          <mat-label class="showBankHeading">Status: </mat-label>
        </div>
        <div fxFlex="70%">
          <mat-label
            class="showBankValue"
            [ngStyle]="{ color: bank.status == true ? 'green' : 'red' }"
          >
            {{ bank.status == true ? "Active" : "Inactive" }}
          </mat-label>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div
        *ngIf="this.userRole != '1'"
        fxLayout="column"
        fxLayoutAlign="end end"
        style="padding-top: 20px"
      >
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <span *ngIf="activateDeactButton">
            <button
              *ngIf="bank.status"
              mat-button
              class="deleteBankBtn"
              (click)="deleteBankAccount(bank, 'deactivate')"
            >
              DEACTIVATE
            </button>
            <button
              *ngIf="!bank.status"
              mat-button
              class="activeBankBtn"
              (click)="deleteBankAccount(bank, 'activate')"
            >
              ACTIVATE
            </button>
          </span>
          &nbsp;&nbsp;
          <button
            mat-button
            class="page-header-button-style"
            (click)="editBankDetails(bank)"
          >
            EDIT
          </button>
        </div>
      </div>
    </mat-expansion-panel>
    <br />
  </mat-accordion>
</div>

<div *ngIf="bankList?.length == 0" class="noBank">No bank account added.</div>
