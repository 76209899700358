<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage">
</app-loading-spinner>

<div class="backHeader" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
  <mat-label>
    <button mat-icon-button (click)="navigateToCustomerGroupPage()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    Back to Customer Groups
  </mat-label>
  <span class="spacer"></span>
  <div fxLayout="column" class="padding-right" fxLayoutAlign="center end">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxFlex="75%" fxLayoutAlign="start stretch">
      <button class="white-button-style" appearance="outline" (click)="navigateToCustomerGroupPage()" mat-button>
        <span>CANCEL</span>
      </button>
      &nbsp;
      <button mat-raised-button color="primary" (click)="updateCustomerGroup('Draft')"
        [disabled]="updateCustomerGrpFormGroup.invalid || selectedCustomer.length === 0 || isAmountTextInvalid"
        class="page-header-button-style">
        SAVE DRAFT
      </button>
      &nbsp;
      <button mat-raised-button color="primary" (click)="updateCustomerGroup('Active')"
        [disabled]="updateCustomerGrpFormGroup.invalid || selectedCustomer.length === 0 || isAmountTextInvalid"
        class="page-header-button-style">
        SAVE & ACTIVATE
      </button>
    </div>
  </div>
</div>
<form [formGroup]="updateCustomerGrpFormGroup" style="margin-bottom:15px">
  <div fxLayout="column" fxLayoutGap="16px">
    <mat-card class="totalTransactionsDiv" fxFlex="100%">
      <div fxLayout="row" fxLayoutGap="16px" fxFlex="100%">
        <mat-form-field fxFlex="70%" appearance="outline">
          <mat-label>Group Name</mat-label>
          <input matInput class="form-control" formControlName="GroupName" type="text" name="GroupName"
            placholder="Enter Group Name" autocomplete="off" maxlength="60" />
          <mat-error>
            {{ updateCustomerGrpFormGroup.controls["GroupName"]["_errorMessage"] }}
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="30%" appearance="outline">
          <mat-label>(Optional) Set defaults for ACH Transaction Type</mat-label>
          <mat-select (selectionChange)="onAchTransactionTypeChange($event.value)" class="form-control"
            formControlName="achTransactionType" name="achTransactionType">
            <mat-option *ngFor="let type of transactionTypes" [value]="type?.value"> {{ type.displayName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-card>
  </div>
</form>
<div fxLayout="row">
  <mat-card class="totalTransactionsDiv" fxFlex="20%">
    <div class="sticky-header">
      <h3 class="margin-unset">Customers ({{ this.page.length }})</h3>
      <mat-form-field appearance="outline" class="form-field-search margin-unset">
        <span matPrefix>
          <mat-icon>search</mat-icon>
        </span>
        <input matInput placeholder="Search" autocomplete="off" [(ngModel)]="filterText" (keyup.enter)="onEnter()"
          (blur)="onBlurSearch()" pattern="{{ searchTextPattern }}" />
        <button *ngIf="filterText" matSuffix mat-icon-button aria-label="Clear" style="padding-bottom: 1.34375em"
          (click)="filterText = ''">
          <mat-icon (click)="onClearText()">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div (scroll)="onScroll($event)" style="overflow-x: hidden !important; height:40rem; padding-top:unset;">
      <app-data-table [columns]="customerscolumn" [data]="unselectedCustomers" [isExport]="false"
        [isLoading]="isLoading" [allColumns]="allColumns" [role]="this.userRole" [isSpacerRequired]="true"
        [datatablemargin]="this.datatablemargin" [showPaginator]="false"
        (rowClickedEvent)="onRowClick($event, 'unselected')" [highlightedRows]="this.highlightedRows"
        [fxFlexCustom]="this.fxFlexCustom" [isFilter]="false" [actionRefresh]="actionRefresh" [sortBy]="sortBy"
        class="customers" [isSearch]="false">
      </app-data-table>
    </div>
  </mat-card>
  <div fxLayout="column" fxLayoutGap="15px">
    <div fxFlex="40px"></div>
    <img src="assets/grid-icons/next.png" alt="Arrow Icon" (click)="moveSelectedCustomersToRight()" class="arrow-image">
    <img src="assets/grid-icons/nextall.png" alt="Arrow Icon" (click)="moveAllToRight()" class="arrow-image">
    <div fxFlex="40px"></div>
    <img src="assets/grid-icons/previous.png" alt="Arrow Icon" (click)="moveSelectedCustomersToLeft()"
      class="arrow-image">
    <img src="assets/grid-icons/previousall.png" alt="Arrow Icon" (click)="moveAllToLeft()" class="arrow-image">
    <div fxFlex="40px"></div>
  </div>
  <mat-card class="totalTransactionsDiv" style="padding:0px" fxFlex="100%">
    <h3 style="padding:16px; padding-bottom: 0px;margin-bottom: 0px;">Customer Groups ({{
      this.selectedCustomerList.length }}) <span (click)="$event.stopPropagation()">
        <img matTooltip="Add new customer" class="sectionSubImg person-image" (click)="openCustomerDialog()"
          src="assets/grid-icons/addCustomer.png" />
      </span>
      <a (click)="openApplyToAllDialog()" *ngIf="selectedCustomer.length > 1"
        style="color: blue; cursor: pointer;float:right">Apply To All</a>
    </h3>
    <app-data-table [columns]="customerGrpscolumn" [data]="selectedCustomer" [isLoading]="isLoading"
      [noRecordMessage]="noRecordMessage" [allColumns]="allColumns" [isExport]="false" [role]="this.userRole"
      [page]="pageSelected" [isFilter]="false" [isSearch]="false" [datatablemargin]="this.datatablemargin"
      (rowClickedEvent)="onRowClick($event, 'selected')" [highlightedRows]="this.highlightedRows"
      (pageChangedEvent)="onPageChanged($event)" (rowDoubleClickedEvent)="onRowDoubleClick($event)"
      (amountTextValidationEvent)="handleAmountValidation($event)" [sortBy]="sortBySelectedCustomers">
    </app-data-table>
  </mat-card>
</div>

<ng-template #applyToAllDialog>
  <h4 mat-dialog-title fxLayoutAlign="center end"><b>Apply To All</b></h4>
  <form [formGroup]="applyToAllFormGroup">
    <div fxLayout="row" fxLayoutGap="16px" fxLayout.xs="column" fxLayoutAlign="start stretch" class="form-field-bottom">

      <mat-form-field fxFlex="50%" appearance="outline">
        <mat-label>ACH Transaction Type</mat-label>
        <mat-select class="form-control" formControlName="transactionTypeApplyAll" name="transactionTypeApplyAll">
          <mat-option *ngFor="let type of transactionTypes" [value]="type.value"> {{ type.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <span style="align-items: center;">
        <img class="form-field-search " matTooltip="doller" src="assets/grid-icons/doller.png" />&nbsp;&nbsp;
        <mat-form-field fxFlex="90%" appearance="outline">

          <mat-label>Default Amount</mat-label>
          <input matInput class="form-control" formControlName="defaultAmount" name="defaultAmount"
            placholder="Enter Default Amount" autocomplete="off" appTwoDigitDecimalNumber />
          <mat-error>
            {{ applyToAllFormGroup.controls["defaultAmount"]["_errorMessage"] }}
          </mat-error>
        </mat-form-field>
      </span>
    </div>
    <div mat-dialog-actions fxLayoutAlign="end end">
      <button class="white-button-style custom-outline-button" appearance="outline" (click)="onCancel()" mat-button>
        <span>CANCEL</span>
      </button>
      <button mat-raised-button color="primary" [disabled]="applyToAllFormGroup.invalid"
        class="page-header-button-style" (click)="onApply()">Apply</button>
    </div>
  </form>
</ng-template>