import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/blockModules/pages/login/sharedService';
import { addUserModel } from 'src/app/sharedModules/models/add-user.model';
import { NotificationService } from 'src/app/sharedModules/services/notification/notification.service';
import {
  UserManagementServiceProxy,
  UserProfileServiceProxy,
} from 'src/app/sharedModules/services/user-management/user-managament.proxies';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  constructor(
    private userManagementService: UserManagementServiceProxy,
    private userProfileService: UserProfileServiceProxy,
    private router: Router,
    private _toastrService: ToastrService,
    private _notifyService: NotificationService,
    private sharedService: SharedService
  ) {
    var isAdminLogin =
      JSON.parse(localStorage.getItem('isAdminLogin')) ?? false;
  }

  getUsers(
    userName: string,
    searchBy: string,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number
  ) {
    return this.userManagementService.getUsers(
      userName,
      searchBy,
      sortBy,
      sortDirection,
      page,
      pageSize
    );
  }
  getUserById(userId: string) {
    return this.userManagementService.getUserById(userId);
  }
  createUser(userModel: any, dialogRef: MatDialogRef<any, any>) {
    this.userManagementService.createUser(userModel).subscribe(
      (res) => {
        if (res.code == 200) {
          this._toastrService.success('The User Added Successfully');
          dialogRef.close();
          this.router.navigate(['/finzeoUsers']);
        } else if (res.code === 400 && res.errorMessage) {
          dialogRef.close();
          this._toastrService.error(res.errorMessage);
        }
      },
      (err) => {
        dialogRef.close();
        if (err.code === 500 && err.errorMessage) {
          this._toastrService.error(err.errorMessage);
        } else {
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      }
    );
  }
  editUser(
    userModel: any,
    dialogRef: MatDialogRef<any, any>,
    isUpdatePassword: boolean
  ) {
    this.userManagementService.editUser(userModel).subscribe(
      (res) => {
        if (res.code == 200) {
          if (isUpdatePassword == false) {
            this._toastrService.success('The User updated Successfully');
            dialogRef.close();
            this.router.navigate(['/finzeoUsers']);
          } else {
            this.changeUserPassword(userModel, dialogRef);
          }
        } else if (res.code === 400 && res.errorMessage) {
          dialogRef.close();
          this._toastrService.error(res.errorMessage);
        }
      },
      (err) => {
        dialogRef.close();
        if (err.code === 500 && err.errorMessage) {
          this._toastrService.error(err.errorMessage);
        } else {
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      }
    );
  }

  changeUserPassword(userModel: any, dialogRef: MatDialogRef<any, any>) {
    const messagePasswordUsed: string = 'Password used already, try different password';
    this.userManagementService.changeUserPassword(userModel).subscribe(
      (res) => {
        if (res.code == 200) {
          this._toastrService.success('The User updated Successfully');
          dialogRef.close();
          this.router.navigate(['/finzeoUsers']);
        } else if (res.errorMessage == messagePasswordUsed){
          this._toastrService.error(
            'Password used already, try different password'
          );
          dialogRef.close();
        }
      },

      (err) => {
        dialogRef.close();
        if (
          err.code === 500 &&
          err.errorMessage.includes(messagePasswordUsed)
        ) {
          this._toastrService.error(
            'Password used already, please try different password.'
          );
        } else {
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      }
    );
  }

  getUserProfile(isAdminLogin: boolean) {
    this.userProfileService.getUserProfile(isAdminLogin).subscribe(
      (res) => {
        if (res.code == 200) {
          localStorage.setItem('userProfile', JSON.stringify(res.data));
          this.sharedService.newUser.next(res.data);
        }
      },

      (err) => {
        if (err || err.status == 401 || err.status == 500) {
          this._notifyService.showError(
            'Your current session times out. Please login again! '
          );
          localStorage.removeItem('userProfile');
          localStorage.removeItem('accessToken');
          localStorage.removeItem('validUser');
          localStorage.removeItem('inventory');
          localStorage.removeItem('invoice');
          localStorage.removeItem('users');
          localStorage.removeItem('dashboard');
          localStorage.removeItem('reports');
          localStorage.removeItem('hostedLink');
          localStorage.removeItem('customers');
          localStorage.removeItem('batchTransactions');
          localStorage.removeItem('instantBankAnalysis');
          localStorage.removeItem('statements');
          localStorage.removeItem('ticket');
          localStorage.removeItem('ach');
          localStorage.removeItem('cards');
          localStorage.removeItem('echeck');
          localStorage.removeItem('instantFund');                   
          localStorage.removeItem('callCompanyList');
          localStorage.removeItem('billing');
          isAdminLogin
            ? window.location.replace('csr')
            : window.location.replace('/');
        }
      }
    );
  }
}
