import {
  required,
  greaterThan,
  prop
} from '@rxweb/reactive-form-validators';

export class ApplyToAllModel {

  // @required()
  @prop()
  transactionTypeApplyAll: string;

  @required()
  @greaterThan({ value: 0, message: 'Default Amount must be greater than zero.' })
  defaultAmount: number;
}
