<app-loading-spinner
  [isLoading]="isLoading"
  [loadMessage]="loadMessage"
></app-loading-spinner>

<div class="page-header-bottom">
  <div class="backHeader">
    <mat-label>
      <button mat-icon-button (click)="navigateToBackPage()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      Reports
    </mat-label>
  </div>
  <div
    class="page-header"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
  >
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div class="page-header-text">Transaction Report</div>
        <div class="page-sub-header-text-1">
          Reports for all type of transactions, amount, individual, company
          related data can be generated from this page
        </div>
      </div>
    </div>
  </div>
</div>

<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
  <div class="report-gererate-form">
    <form [formGroup]="reportGenerateFormGroup">
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="16px"
        fxLayoutAlign="start stretch"
        class="form-group"
      >
        <div fxLayout="column" fxFlex="25%">
          <mat-form-field appearance="outline">
            <input
              matInput
              formControlName="customerName"
              [matAutocomplete]="auto"
              placeholder="Customer Name (min 3 characters)"
              style="padding-bottom: 2%"
              pattern="^[a-zA-Z ]*$"
            />
            <button
              *ngIf="this.reportGenerateFormGroup.get('customerName').value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="clearSelection()"
            >
              <mat-icon>close</mat-icon>
            </button>
            <button matPrefix mat-icon-button>
              <mat-icon>search</mat-icon>
            </button>
            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="onSelected()"
            >
              <mat-option *ngIf="isLoading" class="is-loading"
                >Loading...</mat-option
              >
              <ng-container *ngIf="!isLoading && filteredCustomer.length > 0">
                <mat-option
                  *ngFor="let customer of filteredCustomer"
                  [value]="selectedCustomerName(customer)"
                >
                  <div class="name-email-style">
                    {{ customer.firstName?.trim() }}&nbsp;{{
                      customer.lastName?.trim()
                    }}
                  </div>
                  <div class="name-email-style sub-text">
                    {{ customer.email }}
                  </div>
                </mat-option>
              </ng-container>
              <ng-container
                *ngIf="isShowNoFound && !isLoading && !filteredCustomer.length"
              >
                <mat-option>
                  <div class="name-email-style">
                    <b>No Records Found</b>
                  </div>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxFlex="25%">
          <mat-form-field appearance="outline">
            <mat-label class="required">Funding Type</mat-label>
            <mat-select
              class="form-control"
              formControlName="typeOfService"
              name="typeOfService"
              (selectionChange)="selectionMoreFilter()"
            >
              <mat-option *ngFor="let type of typeList" [value]="type.key"
                >{{ type.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- <div fxLayout="column" fxFlex="25%">
          <mat-form-field appearance="outline">
            <mat-label class="required">Processor</mat-label>
            <mat-select
              class="form-control"
              formControlName="processor"
              name="processor"
            >
              <mat-option
                *ngFor="let processor of processorList"
                [value]="processor.key"
                >{{ processor.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
        <div fxLayout="column" fxFlex="25%">
          <mat-form-field appearance="outline">
            <mat-label class="required">Sort Data</mat-label>
            <mat-select
              (selectionChange)="selectionChangeSortData()"
              class="form-control"
              formControlName="sortData"
              name="sortData"
            >
              <mat-option
                *ngFor="let sortData of sortDataList"
                [value]="sortData.key"
                >{{ sortData.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxFlex="25%">
          <mat-form-field appearance="outline">
            <mat-label class="required">Status</mat-label>
            <mat-select
              class="form-control"
              formControlName="status"
              name="status"
              (selectionChange)="onStatusChanged($event.value)"
            >
              <mat-option *ngFor="let status of statusList" [value]="status.key"
                >{{ status.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutWrap
        fxLayoutGap="16px"
        fxLayoutAlign="start stretch"
        class="form-group"
      >
        <div fxLayout="column" fxFlex="25%">
          <mat-form-field appearance="outline">
            <mat-label class="required">Show Date Type</mat-label>
            <mat-select
              class="form-control"
              formControlName="showDateType"
              name="showDateType"
            >
              <mat-option
                *ngFor="let showData of showDateTypeList"
                [value]="showData.key"
                >{{ showData.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxFlex="25%">
          <mat-form-field appearance="outline">
            <mat-label>Date Range</mat-label>
            <mat-date-range-input
              [rangePicker]="dateRangePicker"
              [min]="minDate"
              [max]="maxDate"
            >
              <input
                matStartDate
                placeholder="Start date"
                formControlName="startDate"
                name="startDate"
                readonly
              />
              <input
                matEndDate
                placeholder="End date"
                formControlName="endDate"
                name="endDate"
                readonly
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="dateRangePicker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxFlex="25%"></div>
        <div fxLayout="column" fxFlex="25%">
          <mat-checkbox formControlName="withNoc"  [disabled]="withNocFlag"  style="font-family: 'Inter';padding: 3px;">
            With NOC
          </mat-checkbox>
        </div>
      </div>
    </form>
  </div>
</div>

<div style="width: 100%">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <mat-accordion class="accoridan-style">
      <mat-expansion-panel
        (opened)="(true)"
        (closed)="(false)"
        [expanded]="panelOpenState"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="page-header-text">
            More Filters
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div
          [formGroup]="reportGenerateFormGroup"
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="16px"
          fxLayoutAlign="start stretch"
          class="form-group"
        >
          <div fxLayout="column" fxFlex="25%">
            <mat-form-field appearance="outline">
              <mat-label>Reports</mat-label>
              <input
                matInput
                formControlName="heading"
                name="heading"
                placeholder="Reports"
                autocomplete="off"
              />
            </mat-form-field>
          </div>
          <div fxLayout="column" fxFlex="75%">
            <mat-form-field appearance="outline">
              <mat-label>Description</mat-label>
              <input
                matInput
                formControlName="description"
                placeholder="Description"
                name="description"
                placholder="Description"
                autocomplete="off"
              />
            </mat-form-field>
          </div>
        </div>
        <br />

        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="16px"
          class="report-gen-header"
        >
          <div fxLayout="column" fxFlex="10%">
            <span class="filter-text">Filter</span>
          </div>
          <div fxLayout="column" fxFlex="30%"></div>
          <div fxLayout="column" fxFlex="60%">
            <span class="filter-text">Defaults/ Presets</span>
          </div>
        </div>
        <div class="report-gen-card">
          <div *ngFor="let report of _reportService.moreFilterList">
            <mat-divider></mat-divider>
            <div
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutAlign="start center"
              fxLayoutWrap
              fxLayoutGap="16px"
              class="report-gen-value"
            >
              <div fxLayout="column" fxLayoutAlign="start center" fxFlex="5%">
                <span class="filter-text">
                  <mat-checkbox
                    [checked]="report.check"
                    [(ngModel)]="report.check"
                    (change)="changeCheckBox(report)"
                  >
                  </mat-checkbox>
                </span>
              </div>
              <div fxLayout="column" fxFlex="25%">
                <span class="filter-name">
                  {{ report.heading }}
                </span>
              </div>
              <div fxLayout="column" fxFlex="70%">
                <div *ngIf="report.type == 'textbox'">
                  <mat-form-field appearance="outline" style="margin-top: 0px">
                    <input
                      matInput
                      [disabled]="!report.check"
                      type="text"
                      [(ngModel)]="report.filter.value"
                      pattern="{{ report.pattern }}"
                    />
                  </mat-form-field>
                </div>
                <div *ngIf="report.type == 'input'">
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
                    <div fxLayout="column" fxFlex="40%">
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
                        <div fxLayout="column" fxFlex="14%">
                          <mat-label style="margin-top: 23px">{{
                            report.filter.minimum.label
                          }}</mat-label>
                        </div>
                        <div fxLayout="column" fxFlex="50%">
                          <mat-form-field
                            appearance="outline"
                            style="margin-top: 0px"
                          >
                            <input
                              matInput
                              [disabled]="!report.check"
                              type="input"
                              [(ngModel)]="report.filter.minimum.value"
                              appTwoDigitDecimalNumber
                              placeholder="0.00"
                              pattern="{{ report.pattern }}"
                              (blur)="onBlurMinNumber(report)"
                            />
                            <span matPrefix>$&nbsp;&nbsp;</span>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                    <div fxLayout="column" fxFlex="2%"></div>
                    <div fxLayout="column" fxFlex="40%">
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
                        <div fxLayout="column" fxFlex="8%">
                          <mat-label style="margin-top: 23px">{{
                            report.filter.maximun.label
                          }}</mat-label>
                        </div>
                        <div fxLayout="column" fxFlex="50%">
                          <mat-form-field
                            appearance="outline"
                            style="margin-top: 0px"
                          >
                            <input
                              matInput
                              class="form-control"
                              [disabled]="!report.check"
                              type="input"
                              [(ngModel)]="report.filter.maximun.value"
                              appTwoDigitDecimalNumber
                              placeholder="0.00"
                              pattern="{{ report.pattern }}"
                              (blur)="onBlurMaxNumber(report)"
                            />
                            <span matPrefix>$&nbsp;&nbsp;</span>
                          </mat-form-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="report.type == 'radio'">
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
                    <div
                      fxLayout="column"
                      fxFlex="20%"
                      *ngFor="let obj of report.filter"
                    >
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
                        <div fxLayout="column" fxFlex="20%">
                          <input
                            [disabled]="!report.check"
                            type="radio"
                            name="{{ report.heading }}"
                            value="{{ obj.label }}"
                            [(ngModel)]="report.value"
                          />
                        </div>
                        <div fxLayout="column" fxFlex="80%">
                          <mat-label>{{ obj.label }}</mat-label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="report.type == 'checkbox'">
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
                    <div
                      fxLayout="column"
                      fxFlex="20%"
                      *ngFor="let obj of report.filter"
                    >
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
                        <div fxLayout="column" fxFlex="20%">
                          <input
                            [disabled]="!report.check"
                            type="checkbox"
                            name="{{ report.type }}"
                            value="{{ obj.label }}"
                            [(ngModel)]="obj.value"
                            (change)="changeCheckBox(report)"
                          />
                        </div>
                        <div fxLayout="column" fxFlex="80%">
                          <mat-label class="ellip" matTooltip="{{ obj.label }}">
                            {{ obj.label }}
                          </mat-label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<div
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign="end end"
  class="btn-right-1"
>
  <button
    [disabled]="!reportGenerateFormGroup.valid"
    mat-raised-button
    color="primary"
    class="page-header-button-style"
    (click)="generateReport()"
  >
    GENERATE
  </button>
</div>
