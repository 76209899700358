import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CustomerBankDetailsService } from 'src/app/featureModules/services/customer-bank-details.service';
import { ConfirmationDialogComponent } from 'src/app/sharedModules/components/dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-bank-accounts',
  templateUrl: './bank-accounts.component.html',
  styleUrls: ['./bank-accounts.component.scss'],
})
export class BankAccountsComponent implements OnInit {
  panelOpenState = true;

  @Input()
  bankList = [];
  isLoading: boolean = false;

  @Input()
  activateDeactButton: boolean = true;

  @Output()
  editBankEvent = new EventEmitter();

  userRole: any;

  constructor(
    private dialog: MatDialog,
    private _customerBankDetailsService: CustomerBankDetailsService,
    private _toastrService: ToastrService
  ) {
    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];
  }

  ngOnInit(): void {}

  deleteBankAccount(bank: any, type: string) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      data: {
        delete: true,
        buttonName: type.toUpperCase(),
        successMessage: 'Are you sure you want to ' + type + ' this?',
        subSuccessMessage:
          'Clicking on ' + type + ' button will update the status',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.isLoading = true;
        this._customerBankDetailsService
          .deactivateCustomerBankAccount(
            bank.customerBankAccountId,
            !bank.status
          )
          .subscribe(
            (data) => {
              this.isLoading = false;
              if (data.data != null) {
                let index = this.bankList.findIndex(
                  (obj) =>
                    obj.customerBankAccountId == bank.customerBankAccountId
                );
                if (index > -1) {
                  this.bankList[index].status = !bank.status;
                }
                this._toastrService.success(
                  'Bank status updated successfully!'
                );
              }
            },
            (error) => {
              this.isLoading = false;
            }
          );
      }
    });
  }

  editBankDetails(bank: any) {
    this.editBankEvent.emit(bank);
  }

  maskCharacter(str, mask, n = 1) {
    return ('' + str).slice(0, -n).replace(/./g, mask) + ('' + str).slice(-n);
  }
}
