import {
  required,
  prop,
  maxLength,
  pattern,
  minLength,
  greaterThan
} from '@rxweb/reactive-form-validators';

export class UpdateCustomerGroupModel {

  @required()
  @prop()
  CustomerGroupId : string;

  @required()
  @maxLength({ value: 60 })
  @minLength({ value: 3 })
  @pattern({ expression: { Alpha_Numbers: /^[a-zA-Z0-9 ,()\-\*#]*$/ } })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  GroupName: string;

  @prop()
  Status: string = 'Draft';

  @prop()
  achTransactionType: string

  @prop()
  CustomerGroupDetails: CustomerGroupDetails[]
}


 class CustomerGroupDetails{
  
  @prop()
  CustomerId: string;

  @prop()
  @greaterThan({ value: 0, message: 'Default Amount must be greater than zero.' })
  DefaultAmount:number

  @prop()
  IsActive : boolean = true;
}