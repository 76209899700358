<app-loading-spinner [isLoading]="isLoading"></app-loading-spinner>

<div style="background-color: #ebeef1" *ngFor="let card of debitCreditCardsList">
  <mat-accordion class="accoridan-style">
    <mat-expansion-panel (opened)="card.panelOpenState = true" (closed)="card.panelOpenState = false"
      [expanded]="panelOpenState">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
            <img width="34" height="24" src="{{ getCardImage(card.cardNumber) }}" />&nbsp;
            <mat-label class="cardHeading">{{
              getCardName(card.cardNumber)
              }}</mat-label>
          </div>
        </mat-panel-title>
        <mat-action-row style="padding-right: 20px">
          <span [ngStyle]="{ color: !card?.isExpiredCard ? 'black' : 'red' }">
            <span *ngIf="card?.isExpiredCard">Expired: &nbsp;</span>
            <span *ngIf="!card?.isExpiredCard">Expires: &nbsp;</span>
            {{ getExpiryDateFormat(card) }}
          </span>
        </mat-action-row>
      </mat-expansion-panel-header>
      <mat-divider></mat-divider>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="start center">
        <div fxFlex="68%">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
            <div fxFlex="30%">
              <mat-label class="showCardHeading">Name On Card: </mat-label>
            </div>
            <div fxFlex="70%">
              <mat-label class="showCardValue">
                {{ card.nameOnCard }}
              </mat-label>
            </div>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
            <mat-divider style="width: 70%"></mat-divider>
          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
            <div fxFlex="30%">
              <mat-label class="showCardHeading">Card Number: </mat-label>
            </div>
            <div fxFlex="70%">
              <mat-label class="showCardValue">
                <span>{{ cardNumberEncryption(card.cardNumber) }}</span>&nbsp;&nbsp;&nbsp;
                <img style="vertical-align: middle" width="34" height="24" src="{{ getCardImage(card.cardNumber) }}" />
              </mat-label>
            </div>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
            <mat-divider style="width: 70%"></mat-divider>
          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
            <div fxFlex="30%">
              <mat-label class="showCardHeading">Address: </mat-label>
            </div>
            <div fxFlex="65%" style="margin-top: 10px;">
              <mat-label class="showCardValue format-address">
                {{
                getAddressFormat(
                card.address,
                card.city,
                card.state,
                card.zip
                )
                }}
              </mat-label>
            </div>
            <div fxFlex="5%"></div>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
            <mat-divider style="width: 70%"></mat-divider>
          </div>

          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
            <div fxFlex="30%">
              <mat-label class="showCardHeading">Status: </mat-label>
            </div>
            <div fxFlex="70%">
              <mat-label class="showCardValue" [ngStyle]="{ color: card.status == true ? 'green' : 'red' }">
                {{ card.status == true ? "Active" : "Inactive" }}
              </mat-label>
            </div>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap>
            <mat-divider style="width: 70%"></mat-divider>
          </div>
        </div>
        <div fxFlex="2%"></div>
        <div fxFlex="30%">
          <img src="assets/grid-icons/card-img.png" style="float: right; margin-right: -35px" />
        </div>
      </div>

      <div *ngIf = "this.userRole != '1'" fxLayout="column" fxLayoutAlign="end end">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <span *ngIf="activateDeactButton">
            <button *ngIf="card.status" mat-button class="deleteCardBtn" (click)="deleteCard(card, 'deactivate')">
              DEACTIVATE
            </button>
            <button *ngIf="!card.status" mat-button class="activeCardBtn" (click)="deleteCard(card, 'activate')">
              ACTIVATE
            </button>
          </span>
          &nbsp;&nbsp;
          <button mat-button class="page-header-button-style" (click)="editCardDetails(card)">
            EDIT
          </button>
        </div>
      </div>
    </mat-expansion-panel>
    <br />
  </mat-accordion>
</div>
<div *ngIf="debitCreditCardsList?.length == 0" class="noCard">
  No card details found.
</div>