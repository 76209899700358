import {
  AfterViewInit,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/blockModules/services/customers.service';
import { MatStepper } from '@angular/material/stepper';
import { AddCustomerModel } from 'src/app/sharedModules/models/add-customer.model';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ACHTransactionModel } from 'src/app/featureModules/models/addach-transaction.model';
import { AchTransactionsService } from 'src/app/featureModules/services/ach-transactions.service';
import moment from 'moment';
import creditCardType, { types as CardType } from 'credit-card-type';
import { CustomerBankDetailsService } from 'src/app/featureModules/services/customer-bank-details.service';
import { Utils } from 'src/app/featureModules/helper/utils';
import {
  catchError,
  fromEvent,
  map,
  merge,
  Observable,
  Observer,
  of,
} from 'rxjs';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { HolidayUtils } from 'src/app/featureModules/helper/holiday.utils';
import { getSafeIsoDateString } from 'src/app/sharedModules/utils/dates';

interface DrowpDown {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-add-ach-transaction',
  templateUrl: './add-ach-transaction.component.html',
  styleUrls: ['./add-ach-transaction.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddAchTransactionComponent implements OnInit, AfterViewInit {
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;

  isLoading: boolean = false;
  cardList = [];

  selectedValue: string;
  selectedCar: string;
  isRecurring: boolean = false;
  isSameDay: boolean = false;
  model: ACHTransactionModel;
  customerBankAccountId;
  customerId;
  processorId;
  selectedBankName: string = '';
  isOnline: any;
  isReturnedRate: boolean;
  messageReturnedRate: string;
  startWindowTime: string;
  endWindowTime: string;
  isSameDayAllowed: boolean;

  // startingHours = 7;
  // startingMinutes = 0;
  // 10:30 AM in EST and 2:30 PM in UTC
  startingHours = 5;
  startingMinutes = 0;

  // endingHours = 15;
  // endingMinutes = 0;
  // 4:45 PM in EST and 8:45 PM in UTC
  endingHours = 21;
  endingMinutes = 45;

  // startingHours = 7;
  // startingMinutes = 0;
  // 9:30 AM in EST and 2:30 PM in UTC and 8:00 PM in IST 
  newStartingHours
  newStartingMinutes

  // endingHours = 15;
  // endingMinutes = 0;
  // 1:00 PM in EST and 6:00 PM in UTC and 11:30 PM in IST
  newEndingHours
  newEndingMinutes

  startingDateTime = new Date();
  endingDateTime = new Date();
  utcStartDateToValidate = new Date();
  utcEndDateToValidate = new Date();
  isValidTime: boolean;
  isValidHoliday: boolean;
  isTransactionDate: boolean;

  processors: DrowpDown[] = [
    { value: 'NMI', viewValue: 'NMI Payment Processor' },
  ];
  frequencies: DrowpDown[] = [
    { value: 'weekly', viewValue: 'Weekly' },
    { value: 'biweekly', viewValue: 'Bi-Weekly' },
    { value: 'monthly', viewValue: 'Monthly' },
    { value: 'yearly', viewValue: 'Yearly' },
  ];
  entryCodeList: DrowpDown[] = [
    { value: 'PPD', viewValue: 'PPD' },
    { value: 'CCD', viewValue: 'CCD' },
  ];
  entryTypeList: DrowpDown[] = [
    { value: 'Credit', viewValue: 'Credit' },
    { value: 'Debit', viewValue: 'Debit' },
  ];
  customerModel: AddCustomerModel;
  existingCustomer: boolean = false;
  userName: string = '';
  userEmail: string = '';
  todaydate = new Date(new Date());
  defaultScheduledDate = new Date(new Date());
  sameDate = new Date(new Date());
  amountModel: ACHTransactionModel;
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('callAPIDialog') callAPIDialog: TemplateRef<any>;
  @ViewChild('callReturnedRate') callReturnedRate: TemplateRef<any>;
  dialogRef: MatDialogRef<any, any>;
  currentUrl: boolean = false;
  //routing params
  previousUrl: string = '';
  selectedTransactionTabIndex: number;
  transactionIndex: number;
  tabIndex: number;
  convenienceFeeAmount: number;

  holidays: any;
  minDate = new Date();
  maxDate = new Date();

  ngAfterViewInit() {
    if (this.existingCustomer) {
      this.firstFormGroup.controls['firstCtrl'].setValue('test');
      setTimeout(() => {
        this.stepper.selectedIndex = 1;
      });
    }
  }

  constructor(
    private _formBuilder: FormBuilder,
    private formBuilder: RxFormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private _toastrService: ToastrService,
    private _achTransactionsService: AchTransactionsService,
    private _bankAccountService: CustomerBankDetailsService,
    private _customerService: CustomerService,
    private _utils: Utils,
    private _holidayUtils: HolidayUtils
  ) {
    if (this)
      this.createOnline$().subscribe((isOnline) => (this.isOnline = isOnline));

    this.defaultScheduledDate.setDate(this.todaydate.getDate() + 1);

    const navigation = this.router.getCurrentNavigation();

    this.previousUrl = navigation?.extras?.state
      ? navigation.extras.state['url']
      : '';

    if (this.previousUrl != '') {
      localStorage.setItem('previousUrl', this.previousUrl);
    } else {
      this.previousUrl = localStorage.getItem('previousUrl');
    }

    this.selectedTransactionTabIndex = navigation?.extras?.state
      ? navigation.extras.state['selectedTransactionTabIndex']
      : 0;

    this.tabIndex = navigation?.extras?.state
      ? navigation.extras.state['tabIndex']
      : 0;

    this.transactionIndex = navigation?.extras?.state
      ? navigation.extras.state['transactionIndex']
      : 0;
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.customerId = params.get('customerId');
    });
    if (this.customerId) {
      this.getCustomerById();
      this.existingCustomer = true;
    }
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required],
      processorCtrl: [null],
    });
    this.amountModel = new ACHTransactionModel();
    this.thirdFormGroup = this.formBuilder.formGroup(this.amountModel);

    this.currentUrl = this.router.url.toString().includes(this.customerId);

    this.minDate.setDate(this.minDate.getDate() + 1);
    this.maxDate.setMonth(this.maxDate.getMonth() + 3);
    this.isValidHoliday = false;
    this.setScheduledDateAndHolidayFilter();

    this.changeDateNew();
  }

  navigateToTransactionsPage() {
    this.router.navigateByUrl(this.previousUrl, {
      state: {
        selectedTransactionTabIndex: this.selectedTransactionTabIndex,
        transactionIndex: this.transactionIndex,
        tabIndex: this.tabIndex,
      },
    });
  }

  onCustomerSelection(customer: any) {
    if (customer != null) {
      this.firstFormGroup.patchValue({ firstCtrl: customer.firstName });
      this.userName = customer.firstName;
      this.userName += customer.lastName ? ' ' + customer.lastName : '';
      this.userEmail = customer.email;
      if (customer.type) {
        this.thirdFormGroup.controls['entryCode'].setValue('CCD');
      } else {
        this.thirdFormGroup.controls['entryCode'].setValue('PPD');
      }
      this.getcutomerAchReturnedRate(customer.customerId);
    } else {
      this.firstFormGroup.patchValue({ firstCtrl: '' });
      this.userName = '';
      this.userEmail = '';
    }
    this.selectedBankName = '';
  }

  getCardDetails(customerId: any) {
    this.isLoading = true;
    this.cardList = [];
    this._bankAccountService
      .GetAllBankAccounts(customerId, true)
      .subscribe((data) => {
        this.isLoading = false;
        if (data.data != null) {
          this.cardList = data.data.listOfBankAccountsToReturn;
          this.convenienceFeeAmount = data.data.convenienceFee;
        }
        this.cardList.forEach((element) => {
          element.bankAccountNumber = element.bankAccountNumber.replace(
            /.(?=.{4,}$)/g,
            '*'
          );
        });
      });
  }

  getcutomerAchReturnedRate(customerId: any) {
    this._achTransactionsService
      .GetCustomerAchReturnedRate(customerId)
      .subscribe(
        (res) => {
          this.isLoading = false;
          if (res.code == 200) {
            this.isReturnedRate = res.data.canAddAch;
            this.customerId = res.data.customerId;

            if (res.data.canAddAch) {
              this.getCardDetails(customerId);
              this.secondFormGroup.patchValue({
                secondCtrl: '',
                processorCtrl: null,
              });
            } else {
              const message =
                'Customer ' +
                this.userName +
                ' has ACH transaction return rate ' +
                res.data.customerReturnRate.toFixed(2) * 100 +
                '% on total ' +
                res.data.totalCustomerTransaction +
                ' transaction. ';
              this.messageReturnedRate = message;
              this.dialogRef = this.dialog.open(this.callReturnedRate);
            }
          }
        },

        (error) => {
          this.isLoading = false;
          this._toastrService.error(error.errorMessage);
        }
      );
  }

  returnedRate() {
    this.router.navigate(['/transactions/add-echeck/' + this.customerId]);
    this.dialogRef.close();
  }

  cancelReturnedRate() {
    this.router.navigateByUrl(this.previousUrl, {
      state: {
        selectedTransactionTabIndex: this.selectedTransactionTabIndex,
        transactionIndex: this.transactionIndex,
        tabIndex: this.tabIndex,
      },
    });
    this.dialogRef.close();
  }

  getCustomerById() {
    this.isLoading = true;
    this._customerService.getCustomerById(this.customerId).subscribe((data) => {
      if (data.data != null) {
        this.customerModel = data.data;
        this.userName = this.customerModel.firstName;
        this.userName += this.customerModel.lastName
          ? ' ' + this.customerModel.lastName
          : '';
        if (this.customerModel.type) {
          this.thirdFormGroup.controls['entryCode'].setValue('CCD');
        } else {
          this.thirdFormGroup.controls['entryCode'].setValue('PPD');
        }
        this.getcutomerAchReturnedRate(this.customerId);
      }
    });
  }

  addAchTransaction() {
    this.isLoading = true;
    this.dialogRef.close();
    this.model = new ACHTransactionModel();
    this.model.customerId = this.customerId;
    this.model.bankAccountId = this.customerBankAccountId;
    this.model.entryType = this.thirdFormGroup.controls['entryType'].value;
    this.model.entryCode = this.thirdFormGroup.controls['entryCode'].value;
    this.model.amount = this.thirdFormGroup.controls['amount'].value;
    this.model.memoText = this.thirdFormGroup.controls['memoText'].value;

    this.model.frequency = this.thirdFormGroup.controls['frequency'].value;
    this.model.installments = this.thirdFormGroup.controls['installments'].value
      ? this.thirdFormGroup.controls['installments'].value
      : 0;
    this.model.IsRecurring = this.thirdFormGroup.controls['IsRecurring'].value;

    this.model.isSameDay = this.thirdFormGroup.controls['isSameDay'].value;

    this.model.scheduledDate =
      this.thirdFormGroup.controls['scheduledDate'].value;

    if (!this.model.isSameDay && !this.isSameDayAllowed) {
      const hours = this.utcStartDateToValidate.getHours();
      const minutes = this.utcStartDateToValidate.getMinutes();
      const updatedScheduledDate = new Date(this.model.scheduledDate);
      updatedScheduledDate.setHours(hours, minutes, 0, 0);
      this.model.scheduledDate = updatedScheduledDate.toISOString();
    }

    if (this.thirdFormGroup.valid) {
      if (this.isOnline == true) {
        this._achTransactionsService.CreateAchTransaction(
          this.model,
          this.dialogRef,
          this.customerId,
          this.previousUrl,
          this.selectedTransactionTabIndex,
          this.transactionIndex,
          this.tabIndex
        );
        this.isLoading = false;
      } else {
        this._toastrService.error(
          'Your network has problem. Please try again!'
        );
      }
    } else {
      this.isLoading = false;
      this._toastrService.warning('Please enter the required fields!');
    }
  }

  createOnline$() {
    return merge<any>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }

  onCardSelected(event: any, card: any) {
    this.cardList.forEach((ob) => (ob.cardSelection = true));
    card.cardSelection = event.value;
    this.customerBankAccountId = card.customerBankAccountId;
    this.customerId = card.customerId;
    this.secondFormGroup.patchValue({ secondCtrl: card.cardSelection });
    this.selectedBankName = card.bankName;
  }

  recurringChecked(event) {
    this.isRecurring = event.checked;
    var scheduledDateConvert = moment(
      this.thirdFormGroup.controls['scheduledDate'].value
    ).format('yyyy/MM/DD');
    var startingDateTimeConvert = moment(this.startingDateTime).format(
      'yyyy/MM/DD'
    );

    if (
      scheduledDateConvert == startingDateTimeConvert &&
      this.isRecurring === false &&
      this.isValidTime === false
    ) {
      this.isSameDayAllowed = false;
      this.isTransactionDate = true;
      this.thirdFormGroup.controls['isSameDay'].setValue(false);
      return;
    }
    if (this.isRecurring === true) {
      this.isSameDayAllowed = false;
      this.isTransactionDate = true;
      // return;
    }

    if (this.thirdFormGroup.controls['scheduledDate'].value) {
      if (this.thirdFormGroup.controls['scheduledDate'].value > new Date()) {
        this.thirdFormGroup.controls['isSameDay'].setValue(false);
      } else {
        this.thirdFormGroup.controls['isSameDay'].setValue(true);
      }
    }

    if (this.isRecurring == false) {
      this.thirdFormGroup.controls['installments'].setValue(0);
      this.thirdFormGroup.controls['totalAmount'].setValue(0);
      this.thirdFormGroup.controls['frequency'].setValue(null);
    } else {
      this.thirdFormGroup.controls['installments'].setValue(null);
      this.thirdFormGroup.controls['totalAmount'].setValue(null);
      this.thirdFormGroup.controls['frequency'].setValue(null);
    }
  }

  sameDaycheck(event) {
    this.checkIsSameDayAllowed();
    if (event.checked) {
      this.minDate = new Date();
      this.thirdFormGroup.controls['scheduledDate'].setValue(this.sameDate);

      this.isSameDayAllowed = true;
      this.isTransactionDate = false;

      if (this.isRecurring === false && this.isValidTime === false) {
        this.isSameDayAllowed = false;
        this.isTransactionDate = false;
        this.thirdFormGroup.controls['isSameDay'].setValue(false);
      }
    }
    if (!event.checked) {
      this.isSameDayAllowed = true;
      this.isTransactionDate = true;
      this.minDate.setDate(new Date().getDate() + 1);
      this.thirdFormGroup.controls['scheduledDate'].setValue(
        this.defaultScheduledDate
      );
    }
  }

  changeDate() {
    const _ = moment();
    var dateformat = new Date(
      this.thirdFormGroup.controls['scheduledDate'].value
    );
    dateformat.setHours(0, 0, 0);
    const date = moment(dateformat).add({
      hours: _.hour(),
      minutes: _.minute(),
      seconds: _.second(),
    });

    this.thirdFormGroup.controls['scheduledDate'].setValue(date.toDate());

    let transactionDate = this.thirdFormGroup.controls['scheduledDate'].value;

    this.checkIsSameDayAllowed();

    if (transactionDate) {
      if (transactionDate > new Date()) {
        this.thirdFormGroup.controls['isSameDay'].setValue(false);
      } else {
        this.thirdFormGroup.controls['isSameDay'].setValue(true);
      }
    }
  }

  changeDateNew() {
    const _ = moment();
    var dateformat = new Date(
      this.thirdFormGroup.controls['scheduledDate'].value
    );
    dateformat.setHours(0, 0, 0);
    const date = moment(dateformat).add({
      hours: _.hour(),
      minutes: _.minute(),
      seconds: _.second(),
    });

    var currentDate = new Date();
    var nextDate = new Date();

    this.thirdFormGroup.controls['scheduledDate'].setValue(currentDate.getDate() + 1);


    let transactionDate = this.thirdFormGroup.controls['scheduledDate'].value;
  }

  calculateTotalAmount() {
    if (this.thirdFormGroup.controls['installments'].value) {
      let totalAmount =
        parseFloat(this.thirdFormGroup.controls['amount'].value) *
        parseFloat(this.thirdFormGroup.controls['installments'].value);
      this.thirdFormGroup.controls['totalAmount'].setValue(
        totalAmount.toFixed(2).toString()
      );
    }
    else {
      this.thirdFormGroup.controls['totalAmount'].setValue("");
    }
  }

  bankEncreption(bankAccountNumber: any) {
    if (bankAccountNumber) {
      bankAccountNumber =
        '**** **** **** ' +
        bankAccountNumber.slice(bankAccountNumber.length - 4);
    }
    return bankAccountNumber;
  }

  getBankName(bankAccountNumber: any) {
    let bankCustomerName = 'Customer Bank Name';
    if (bankAccountNumber) {
      var visaCards = creditCardType(bankAccountNumber);
      if (visaCards && visaCards[0] && visaCards[0].niceType)
        bankCustomerName = visaCards[0].niceType;
    }
    return bankCustomerName;
  }

  getBankImage(bankAccountNumber: any) {
    let bankImageName = 'assets/grid-icons/dummy-bank.png';
    if (bankAccountNumber) {
      var visaCards = creditCardType(bankAccountNumber);
      if (visaCards && visaCards[0] && visaCards[0].type)
        bankImageName = 'assets/grid-icons/' + visaCards[0].type + '.png';
    }
    return bankImageName;
  }

  openDialog() {
    if (this.thirdFormGroup.valid) {
      this.dialogRef = this.dialog.open(this.callAPIDialog);
    }
  }

  checkIsSameDayAllowed() {
    this._achTransactionsService
      .GetClientTimeZoneSameDay()
      .subscribe(
        (res) => {
          this.isLoading = false;
          if (res.code == 200) {
            this.newStartingHours = res.data.startTimeHrs;
            this.newStartingMinutes = res.data.startTimeMinut;
            this.newEndingHours = res.data.endTimeHrs;
            this.newEndingMinutes = res.data.endTimeMinut;
          }

          if (
            this._holidayUtils.is_publicHoliday(
              getSafeIsoDateString(this.startingDateTime),
              this.holidays
            ) ||
            this.startingDateTime.getDay() == 0 ||
            this.startingDateTime.getDay() == 6
          ) {
            this.isValidHoliday = true;
            this.isValidTime = false;
            this.isSameDayAllowed = false;
          } else {
            let originalStateDateTime = this.startingDateTime;

            this.utcStartDateToValidate.setUTCDate(
              this.utcStartDateToValidate.getUTCDate()
            );
            this.utcStartDateToValidate.setUTCMonth(
              this.utcStartDateToValidate.getUTCMonth()
            );
            this.utcStartDateToValidate.setUTCFullYear(
              this.utcStartDateToValidate.getUTCFullYear()
            );
            this.utcStartDateToValidate.setUTCHours(this.newStartingHours);
            this.utcStartDateToValidate.setUTCMinutes(this.newStartingMinutes);
            this.utcStartDateToValidate.setUTCSeconds(0);

            this.utcEndDateToValidate.setUTCDate(
              this.utcEndDateToValidate.getUTCDate()
            );
            this.utcEndDateToValidate.setUTCMonth(
              this.utcEndDateToValidate.getUTCMonth()
            );
            this.utcEndDateToValidate.setUTCFullYear(
              this.utcEndDateToValidate.getUTCFullYear()
            );
            this.utcEndDateToValidate.setUTCHours(this.newEndingHours);
            this.utcEndDateToValidate.setUTCMinutes(this.newEndingMinutes);
            this.utcEndDateToValidate.setUTCSeconds(0);

            // if (
            //   this.startingDateTime.getDate() ==
            //     this.utcStartDateToValidate.getUTCDate() &&
            //   this.startingDateTime.getMonth() ==
            //     this.utcStartDateToValidate.getUTCMonth() &&
            //   this.startingDateTime.getFullYear() ==
            //     this.utcStartDateToValidate.getUTCFullYear()
            // ) {
            if (
              this._holidayUtils.is_publicHoliday(
                getSafeIsoDateString(this.startingDateTime),
                this.holidays
              )
            ) {
              originalStateDateTime = this.startingDateTime;

              do {
                this.startingDateTime = this._holidayUtils.addDays(
                  this.startingDateTime,
                  1
                );
              } while (
                this._holidayUtils.is_publicHoliday(
                  getSafeIsoDateString(this.startingDateTime),
                  this.holidays
                )
              );
            }
            if (
              this.startingDateTime > this.utcStartDateToValidate &&
              this.endingDateTime < this.utcEndDateToValidate
              // && this.startingDateTime == originalStateDateTime
            ) {
              this.isValidTime = true;
            } else {
              this.isValidTime = false;
            }

            if (this.isValidTime) {
              this.isSameDayAllowed = true;
              this.isTransactionDate = true;
            } else {
              this.isSameDayAllowed = false;
              this.isTransactionDate = true;
            }

            // } else {
            //   this.isValidTime = false;
            // }

            var timeZone = moment.tz.guess();
            var timeZoneOffset = this.startingDateTime.getTimezoneOffset();
            var timeZoneAbbr = moment.tz.zone(timeZone).abbr(timeZoneOffset);
            this.startWindowTime =
              this._holidayUtils.formatTime(this.utcStartDateToValidate) +
              ' ' +
              timeZoneAbbr;
            this.endWindowTime =
              this._holidayUtils.formatTime(this.utcEndDateToValidate) +
              ' ' +
              timeZoneAbbr;
          }
        });

  }

  setScheduledDateAndHolidayFilter() {
    const onSuccess = (data: any) => {
      this.holidays = data.data;
    };
    const onError = (e) => {
      return of([]);
    };

    this._achTransactionsService
      .GetUpcomingHolidays(this.minDate.getFullYear())
      .pipe(map(onSuccess), catchError(onError))
      .subscribe(() => {
        this.defaultScheduledDate =
          this._holidayUtils.setDefaultScheduleDateUsingUpcomingHoliday(
            this.defaultScheduledDate,
            this.holidays
          );

        this.thirdFormGroup.controls['scheduledDate'].setValue(
          this.defaultScheduledDate
        );

        this.checkIsSameDayAllowed();
        // if (this.isValidTime) {
        //   this.isSameDayAllowed = true;
        //   this.isTransactionDate = true;
        // } else {
        //   this.isSameDayAllowed = false;
        //   this.isTransactionDate = true;
        // }
        this.isLoading = false;
      });
  }

  holidayFilter = (date: Date): boolean => {
    return this._holidayUtils.getHolidaysFilter(this.holidays, date);
  };

  dateClass: MatCalendarCellClassFunction<Date> = (date: Date, view) => {
    return this._holidayUtils.getDateClass(this.holidays, date, view);
  };
}
